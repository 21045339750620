<nsa-spinner></nsa-spinner>

@if(!isLogin && !isErrorPage) {
  @if(user$ | ngrxPush; as user) {
    <header>
      <nsa-header
        nsaId="nsf-header"
        class="fixed-top"
        appName="APP_NAME"
        [translationParams]="{ environment: environment() }"
        [profileName]="user.name"
        [profileSurname]="user.lastname"
        (brandClick)="onBrandClick()"
        (profileClick)="onProfileClick()"
        (logout)="onLogout()">
      </nsa-header>
    </header>
  }
}

<main>
  <div [ngClass]="{ 'container-md py-5 nsf-container': !isLogin && !isErrorPage }">

    <nsa-breadcrumb
      [homePage]="{ url: '', label: 'DASHBOARD.LABEL' }">
    </nsa-breadcrumb>

    <router-outlet></router-outlet>
  </div>
</main>

@if(!isLogin && !isErrorPage) {
  <div class="nsf-fab-container d-flex flex-column justify-content-end position-fixed align-items-center">
    <button
      type="button"
      class="btn btn-nsa btn-md btn-primary rounded-circle"
      (click)="onHelpClick()">
      <i class="bi bi-question fs-5"></i>
    </button>
  </div>
}
