import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { IResponse } from '@core/models/interfaces/response.interface';
import { ITypology } from '@shared/models/interfaces/typology.interface';
import { FormConfigService } from '@core/services/form-config.service';
import { ISearchResponse } from '@shared/models/interfaces/search-response.interface';
import { IUsersMgmtSearchFilters } from '@shared/models/interfaces/users/users-mgmt-search-filters.interface';
import { IUserMgmtSearchData } from '@shared/models/interfaces/users/user-mgmt-search-data.interface';
import { IUserMgmt } from '@shared/models/interfaces/users/user-mgmt.interface';
import { IUserMgmtUpdateRequest } from '@features/users-management/models/interfaces/user-mgmt-update-request.interface';
import { ITimebank } from '@shared/models/interfaces/timebank.interface';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private _httpClient: HttpClient = inject(HttpClient);
  private _formConfigService: FormConfigService = inject(FormConfigService);

  getTimebank(userId: number): Observable<ITimebank> {
    return this._httpClient.get<IResponse<ITimebank>>(`/timeBank/${userId}`).pipe(
      map(({ data }) => data)
    );
  }

  searchUsers(term: string, maxResults?: number): Observable<ITypology[]> {
    return this._httpClient.post<IResponse<ITypology[]>>(`/users/autocomplete`, { searchText: term, maxResults: maxResults || this._formConfigService.config.autoCompleteMaxResults }).pipe(
      map(({ data }) => data)
    );
  }

  getUsers(filters: IUsersMgmtSearchFilters): Observable<ISearchResponse<IUserMgmtSearchData>> {
    return this._httpClient.post<IResponse<ISearchResponse<IUserMgmtSearchData>>>(`/users/search`, { ...filters }).pipe(
      map(({ data }) => data)
    );
  }

  getUser(userId: number): Observable<IUserMgmt> {
    return this._httpClient.get<IResponse<IUserMgmt>>(`/users/${userId}/detail`).pipe(
      map(({ data }) => data)
    );
  }

  updateUser(user: IUserMgmtUpdateRequest): Observable<boolean> {
    return this._httpClient.put<IResponse<boolean>>(`/users/${user.id}`, { ...user }).pipe(
      map(({ data }) => data)
    );
  }

  deleteUser(userId: number): Observable<boolean> {
    return this._httpClient.delete<IResponse<boolean>>(`/users/${userId}`).pipe(
      map(({ data }) => data)
    );
  }

}
