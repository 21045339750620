import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ITableColumn } from '@nesea/ngx-ui-kit/shared';
import { IAbsenceTimesheet } from '@shared/models/interfaces/timesheet.interface';

type AbsenceTimesheet = { type: string, hours: number };

@Component({
    selector: 'nsf-timesheet-summary-absences',
    templateUrl: './timesheet-summary-absences.component.html',
    styleUrl: './timesheet-summary-absences.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TimesheetSummaryAbsencesComponent implements OnInit {

  @Input({ required: true }) absences: IAbsenceTimesheet[];

  columns: ITableColumn<AbsenceTimesheet>[] = [];
  items: AbsenceTimesheet[] = [];

  ngOnInit(): void {
    this.columns = [
      {
        id: 'type',
        label: 'LABEL.TYPE.TEXT',
        property: 'type'
      },
      {
        id: 'hours',
        label: 'LABEL.TOTAL_HOURS.TEXT',
        property: 'hours',
        getValue: (item): string => {
          const hours: number = Math.floor(item.hours);
          const minutes: number = Math.round((item.hours - hours) * 60);
          return `${hours}h : ${minutes.toString().padStart(2, '0')}m`;
        }
      }
    ];

    this.items = this.absences.reduce((output, item) => {
      const type: string = item.tipo;
      const found: AbsenceTimesheet = output.find(abs => abs.type === type);
      !!found ? found.hours += item.ore : output.push({ type: item.tipo, hours: item.ore });
      return output;
    }, [] as AbsenceTimesheet[]);
    this.items.sort((a, b) => a.type.localeCompare(b.type));
  }

}
