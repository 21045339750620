import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ITableColumn } from '@nesea/ngx-ui-kit/shared';
import { DateUtils } from '@shared/utils/date.utils';
import { ITimesheetHistory } from '@shared/models/interfaces/timesheet-history.interface';

@Component({
    selector: 'nsf-timesheet-summary-history',
    templateUrl: './timesheet-summary-history.component.html',
    styleUrl: './timesheet-summary-history.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TimesheetSummaryHistoryComponent implements OnInit {

  @Input() history: ITimesheetHistory[];

  items: ITimesheetHistory[] = [];
  columns: ITableColumn<ITimesheetHistory>[] = [];

  ngOnInit(): void {
    this.columns = [
      {
        id: 'date',
        label: 'LABEL.DATE.TEXT',
        property: 'data',
        getValue: (item: ITimesheetHistory) => DateUtils.formatDate(item.data, true)
      },
      {
        id: 'status',
        label: 'Stato',
        property: 'stato'
      },
      {
        id: 'note',
        label: 'LABEL.NOTE.TEXT',
        property: 'note'
      }
    ];

    this.items = [...this.history];
    this.items.sort((a, b) => new Date(b.data).getTime() - new Date(a.data).getTime());
  }

}
