import * as i0 from '@angular/core';
import { Injectable, Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { SharedUtils, AnimationUtils } from '@nesea/ngx-ui-kit/shared';
import { AsyncPipe } from '@angular/common';
function SpinnerComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 0)(1, "div", 1);
    i0.ɵɵelement(2, "div", 2);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("id", ctx_r0.getSpinnerId())("@fadeIn", "in");
  }
}
class SpinnerService {
  constructor() {
    this._counter$ = new BehaviorSubject(0);
  }
  get loading$() {
    return this._counter$.asObservable().pipe(map(counter => counter > 0));
  }
  show() {
    const counter = this._counter$.value + 1;
    this._counter$.next(counter);
  }
  hide() {
    let counter = this._counter$.value - 1;
    if (counter < 0) counter = 0;
    this._counter$.next(counter);
  }
  static {
    this.ɵfac = function SpinnerService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SpinnerService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SpinnerService,
      factory: SpinnerService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SpinnerService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class SpinnerComponent {
  constructor(_spinnerService) {
    this._spinnerService = _spinnerService;
  }
  ngOnInit() {
    this.loading$ = this._spinnerService.loading$;
  }
  getSpinnerId() {
    return SharedUtils.generateId('spinner');
  }
  static {
    this.ɵfac = function SpinnerComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SpinnerComponent)(i0.ɵɵdirectiveInject(SpinnerService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SpinnerComponent,
      selectors: [["nsa-spinner"]],
      decls: 2,
      vars: 3,
      consts: [[1, "nsa-spinner-overlay", 3, "id"], [1, "nsa-spinner-container"], [1, "nsa-spinner"]],
      template: function SpinnerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, SpinnerComponent_Conditional_0_Template, 3, 2, "div", 0);
          i0.ɵɵpipe(1, "async");
        }
        if (rf & 2) {
          i0.ɵɵconditional(i0.ɵɵpipeBind1(1, 1, ctx.loading$) ? 0 : -1);
        }
      },
      dependencies: [AsyncPipe],
      encapsulation: 2,
      data: {
        animation: [AnimationUtils.fadeIn]
      },
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SpinnerComponent, [{
    type: Component,
    args: [{
      selector: 'nsa-spinner',
      changeDetection: ChangeDetectionStrategy.OnPush,
      animations: [AnimationUtils.fadeIn],
      imports: [AsyncPipe],
      template: "@if(loading$ | async) {\r\n  <div\r\n    [id]=\"getSpinnerId()\"\r\n    [@fadeIn]=\"'in'\"\r\n    class=\"nsa-spinner-overlay\">\r\n    <div class=\"nsa-spinner-container\">\r\n      <div class=\"nsa-spinner\"></div>\r\n    </div>\r\n  </div>\r\n}\r\n"
    }]
  }], () => [{
    type: SpinnerService
  }], null);
})();
class SpinnerModule {
  static {
    this.ɵfac = function SpinnerModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SpinnerModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: SpinnerModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SpinnerModule, [{
    type: NgModule,
    args: [{
      imports: [SpinnerComponent],
      exports: [SpinnerComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { SpinnerComponent, SpinnerModule, SpinnerService };
