import * as i0 from '@angular/core';
import { Component, Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
const _c0 = ["*"];
class AlertComponent {
  constructor() {
    this.className = '';
  }
  static {
    this.ɵfac = function AlertComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AlertComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AlertComponent,
      selectors: [["nsa-alert"]],
      inputs: {
        color: "color",
        className: "className"
      },
      ngContentSelectors: _c0,
      decls: 2,
      vars: 4,
      consts: [["role", "alert"]],
      template: function AlertComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵprojection(1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵclassMapInterpolate2("alert alert-", ctx.color, " ", ctx.className, "");
        }
      },
      dependencies: [CommonModule],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AlertComponent, [{
    type: Component,
    args: [{
      selector: 'nsa-alert',
      imports: [CommonModule],
      template: "<div\r\n  class=\"alert alert-{{color}} {{className}}\"\r\n  role=\"alert\">\r\n  <ng-content></ng-content>\r\n</div>\r\n"
    }]
  }], null, {
    color: [{
      type: Input,
      args: [{
        required: true
      }]
    }],
    className: [{
      type: Input
    }]
  });
})();
class AlertModule {
  static {
    this.ɵfac = function AlertModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AlertModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: AlertModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [AlertComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AlertModule, [{
    type: NgModule,
    args: [{
      imports: [AlertComponent],
      exports: [AlertComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AlertComponent, AlertModule };
