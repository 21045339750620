<nsa-modal *transloco="let transloco">
  <ng-container modal-body>
    <div class="px-2 pb-4">
      <div class="row">
        <div class="col-12 col-lg-4 p-2">
          <nsa-input
            nsaId="nsf-profile-name-id"
            nsaName="nsf-profile-name-name"
            [cardView]="true"
            label="HEADER.PROFILE.MODAL.NAME"
            [ngModel]="data.user.name"
            [disabled]="true">
          </nsa-input>
        </div>

        <div class="col-12 col-lg-4 p-2">
          <nsa-input
            nsaId="nsf-profile-lastname-id"
            nsaName="nsf-profile-lastname-name"
            [cardView]="true"
            label="HEADER.PROFILE.MODAL.LASTNAME"
            [ngModel]="data.user.lastname"
            [disabled]="true">
          </nsa-input>
        </div>

        <div class="col-12 col-lg-4 p-2">
          <nsa-input
            nsaId="nsf-profile-email-id"
            nsaName="nsf-profile-email-name"
            [cardView]="true"
            label="HEADER.PROFILE.MODAL.EMAIL"
            [ngModel]="data.user.email"
            [disabled]="true">
          </nsa-input>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-4 p-2">
          <nsa-input
            nsaId="nsf-profile-role-id"
            nsaName="nsf-profile-role-name"
            [cardView]="true"
            label="HEADER.PROFILE.MODAL.ROLE"
            [ngModel]="data.user.role?.name"
            [disabled]="true">
          </nsa-input>
        </div>

        <div class="col-12 col-lg-4 p-2">
          <nsa-input
            nsaId="nsf-profile-business-area-id"
            nsaName="nsf-profile-business-area-name"
            [cardView]="true"
            label="USERS_MANAGEMENT.BUSINESS_AREA.LABEL"
            [ngModel]="data.user.businessArea?.name"
            [disabled]="true">
          </nsa-input>
        </div>

        <div class="col-12 col-lg-4 p-2">
          <nsa-input
            nsaId="nsf-profile-expertise-center-id"
            nsaName="nsf-profile-expertise-center-name"
            [cardView]="true"
            label="USERS_MANAGEMENT.EXPERTISE_CENTER.LABEL"
            [ngModel]="data.user.competenceCenter?.name"
            [disabled]="true">
          </nsa-input>
        </div>
      </div>

      <div class="d-flex justify-content-end pt-2">
        <nsa-checkbox
          nsaId="nsf-profile-other-id"
          nsaName="nsf-profile-other-name"
          label="LABEL.OTHER_DATA.TEXT"
          [(ngModel)]="otherData"
          [reverse]="true">
        </nsa-checkbox>
      </div>

      @if(otherData) {
        <hr>

        <div class="row ">
          <div class="col-12 col-lg-4 p-2">
            <nsa-input
              nsaId="nsf-profile-timebank-id"
              nsaName="nsf-profile-timebank-name"
              [cardView]="true"
              label="MY_ABSENCES.TIMEBANK.LABEL"
              [ngModel]="timebank"
              [disabled]="true">
            </nsa-input>
          </div>
        </div>
      }
    </div>
  </ng-container>
</nsa-modal>
