import { Injectable } from '@angular/core';
import { ToastService } from '@nesea/ngx-ui-kit/toast';
import { IError } from '@core/models/interfaces/error.interface';
import { AuthService } from '@core/services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(
    private _authService: AuthService,
    private _toastService: ToastService
  ) { }

  showError(status: number, url: string, error: IError): void {
    let message: string;
    if(url.includes(this._authService.tokenEndpoint)) {
      message = error['error_description'] || error['error'];
    }
    if(!message) {
      if([401, 403].includes(status)) {
        message = `ERROR_CODE.${status}`;
      } else if(!!error?.result) {
        message = (error.result.messages || []).map(({ description }, index, messages) => {
          return index < messages.length - 1 ? description + '\n' : description;
        }).join('');
      } else if(!!error?.error) {
        message = error.error;
      } else {
        message = `ERROR_CODE.${status}`;
      }
    }
    this._toastService.showDanger({
      title: 'LABEL.ERROR',
      message
    });
  }

}
