import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ErrorCodeEnum } from '@core/enums/error-code.enum';
import { selectErrorCode } from '@core/store/selectors/core.selectors';
import { redirectToDashboard, redirectToLogin, reloadApplication } from '@core/store/actions/core.actions';

@Component({
  selector: 'nsf-error-page',
  imports: [
    SharedModule
  ],
  templateUrl: './error-page.component.html',
  styleUrl: './error-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorPageComponent implements OnInit {

  errorCode$: Observable<ErrorCodeEnum>;

  constructor(
    private _store: Store
  ) {}

  ngOnInit(): void {
    this.errorCode$ = this._store.select(selectErrorCode);
  }

  onClick(errorCode: ErrorCodeEnum): void {
    if(errorCode === ErrorCodeEnum.INVALID_CONFIG) {
      this._store.dispatch(reloadApplication());
    } else if(errorCode === ErrorCodeEnum.FORBIDDEN) {
      this._store.dispatch(redirectToLogin());
    } else {
      this._store.dispatch(redirectToDashboard());
    }
  }

}
