import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractModal, ModalViewerService } from '@nesea/ngx-ui-kit/modal';
import { FileUtils, IModalInput, IModalOutput } from '@nesea/ngx-ui-kit/shared';
import { map, Observable, take } from 'rxjs';

export interface IFilePreviewModalInput extends IModalInput {
  file: File | Blob;
}
export interface IFilePreviewModalOutput extends IModalOutput {
}


@Component({
    selector: 'nsf-file-preview-modal',
    templateUrl: './file-preview-modal.component.html',
    styleUrl: './file-preview-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FilePreviewModalComponent extends AbstractModal<IFilePreviewModalInput, IFilePreviewModalOutput> {

  fileSrc$: Observable<string>;

  constructor(
    protected override modalViewerService: ModalViewerService
  ) {
    super(modalViewerService);
  }

  override onInit(): void {
    this.fileSrc$ = FileUtils.addMimeTypeToBlob(this.data.file).pipe(
      take(1),
      map(file => `${URL.createObjectURL(file)}${file.type === 'application/pdf' ? '#toolbar=0#view=Fit' : ''}`)
    );
  }
  override onDestroy(): void {}

  onAbort(): void {
    this.close();
  }

}
