import { HttpContextToken, HttpErrorResponse, HttpEvent, HttpHeaders, HttpInterceptorFn, HttpParams, HttpRequest } from '@angular/common/http';
import { SpinnerService } from '@nesea/ngx-ui-kit/spinner';
import { inject } from '@angular/core';
import { BehaviorSubject, catchError, filter, finalize, Observable, switchMap, take, throwError } from 'rxjs';
import { ErrorService } from '@core/services/error.service';
import { Store } from '@ngrx/store';
import { selectSessionData } from '@core/store/selectors/core.selectors';
import { AuthService } from '@core/services/auth.service';
import { redirectToErrorPage, redirectToLogin, refreshTokenFailed, setTokenFailed, updateToken } from '@core/store/actions/core.actions';
import { NServicesHttpErrorResponse } from '@core/models/custom-http-error.model';
import { ErrorCodeEnum } from '@core/enums/error-code.enum';
import { ConfigService } from '@core/services/config.service';
import { IConfig } from '@core/models/interfaces/config.interface';

export const SKIP_TOKEN = new HttpContextToken(() => false);

let isRefreshTokenInProgress: boolean = false;
const refreshToken$: BehaviorSubject<{ idToken: string, accessToken: string, refreshToken: string }> = new BehaviorSubject(null);

const JSON_PATTERN: RegExp = /\.json$/i;

const AUTHORIZATION: string = 'Authorization';
const ACCESS_TOKEN: string = 'Access-token';
const USERNAME: string = 'username';

export const coreInterceptor: HttpInterceptorFn = (req, next) => {
  const configService: ConfigService = inject(ConfigService);
  const spinnerService: SpinnerService = inject(SpinnerService);
  const errorService: ErrorService = inject(ErrorService);
  const authService: AuthService = inject(AuthService);
  const store: Store = inject(Store);

  spinnerService.show();

  return store.select(selectSessionData).pipe(
    take(1),
    switchMap(({ email, idToken, accessToken }) => {
      req = buildRequest(req, configService.config, email, idToken, accessToken);

      return next(req).pipe(
        catchError((err: HttpErrorResponse) => isBlobError(err) ? parseBlobError(err) : throwError(() => err)),
        catchError((err: HttpErrorResponse) => {
          // generateToken or refreshToken
          if(req.url.includes(authService.tokenEndpoint)) {
            errorService.showError(err.status, err.url, err.error);

            if((req.body as HttpParams).get('grant_type').includes('authorization_code')) {
              store.dispatch(setTokenFailed());
            } else {
              store.dispatch(refreshTokenFailed());
            }
            // !!err.code && err.error === NServicesHttpErrorEnum.INVALID_NONCE

            return throwError(() => err);
          } else if(req.url.includes(authService.userInfoEndpoint) && err.status !== 401) {
            errorService.showError(err.status, err.url, err.error);
            store.dispatch(redirectToErrorPage({ errorCode: ErrorCodeEnum.FORBIDDEN }));

            return throwError(() => err);
          } else if(!req.url.includes(authService.logoutEndpoint) && err.status === 401) {
            if(isRefreshTokenInProgress) {
              return refreshToken$.pipe(
                filter(tokenData => !!tokenData),
                take(1),
                switchMap(({ idToken: newIdToken, accessToken: newAccessToken }) => next(updateRequest(req, configService.config, newIdToken, newAccessToken)).pipe(
                  catchError((err: HttpErrorResponse) => {
                    if(err.status === 401) {
                      store.dispatch(redirectToLogin());
                    } else if(err.url.includes(authService.userInfoEndpoint)) {
                      store.dispatch(redirectToErrorPage({ errorCode: ErrorCodeEnum.FORBIDDEN }));
                    }

                    errorService.showError(err.status, err.url, err.error);
                    return throwError(() => err);
                  })
                ))
              );
            } else {
              isRefreshTokenInProgress = true;
              refreshToken$.next(null);

              return authService.refreshToken().pipe(
                catchError((err: NServicesHttpErrorResponse) => {
                  isRefreshTokenInProgress = false;
                  refreshToken$.complete();

                  return throwError(() => err);
                }),
                switchMap(({ id_token: newIdToken, access_token: newAccessToken, refresh_token: newRefreshToken }) => {
                  isRefreshTokenInProgress = false;
                  refreshToken$.next(({ idToken: newIdToken, accessToken: newAccessToken, refreshToken: newRefreshToken }));

                  store.dispatch(updateToken({ idToken: newIdToken, accessToken: newAccessToken, refreshToken: newRefreshToken }));

                  return next(updateRequest(req, configService.config, newIdToken, newAccessToken)).pipe(
                    catchError((err: HttpErrorResponse) => {
                      if(err.status === 401) {
                        store.dispatch(redirectToLogin());
                      } else if(err.url.includes(authService.userInfoEndpoint)) {
                        store.dispatch(redirectToErrorPage({ errorCode: ErrorCodeEnum.FORBIDDEN }));
                      }

                      errorService.showError(err.status, err.url, err.error);
                      return throwError(() => err);
                    })
                  );
                })
              );
            }
          } else {
            errorService.showError(err.status, err.url, err.error);
            return throwError(() => err);
          }
        }),
        finalize(() => spinnerService.hide())
      );
    })
  );
};

const isAssets = (url: string): boolean => {
  return url.includes('assets');
};

const isAuth = (url: string, config: IConfig): boolean => {
  return url.includes(config.auth.issuer);
};

const isBe = (url: string, config: IConfig): boolean => {
  return !isAssets(url) && !isAuth(url, config);
};

const getApiBaseUrl = (url: string, config: IConfig): string => {
  return isBe(url, config) ? config.apiBaseUrl : '';
};

const buildRequest = (request: HttpRequest<unknown>, config: IConfig, email: string, idToken: string, accessToken: string): HttpRequest<unknown> => {
  const skipToken: boolean = request.context.get(SKIP_TOKEN);

  let headers: HttpHeaders = request.headers;

  if(!skipToken) {
    if(isBe(request.url, config)) {
      headers = headers.set(AUTHORIZATION, `Bearer ${idToken}`);

      if(!!accessToken) headers = headers.set(ACCESS_TOKEN, accessToken);
      if(!!email) headers = headers.set(USERNAME, email);
    }
  }

  let url: string = `${getApiBaseUrl(request.url, config)}${request.url}`;
  if(JSON_PATTERN.test(request.url)) {
    url += `?v=${new Date().getTime()}`
  }

  return request.clone({
    url,
    headers
  });
};

const updateRequest = (request: HttpRequest<unknown>, config: IConfig, idToken: string, accessToken: string): HttpRequest<unknown> => {
  let headers: HttpHeaders = request.headers;
  if(isBe(request.url, config)) {
    headers = headers
      .set(AUTHORIZATION, `Bearer ${idToken}`)
      .set(ACCESS_TOKEN, accessToken);
  }

  return request.clone({ headers });
};

const isBlobError = (err: HttpErrorResponse): boolean => {
  return err.error instanceof Blob && err.error?.type === 'application/json';
};

const parseBlobError = (err: HttpErrorResponse): Observable<HttpEvent<unknown>> => {
  const reader: FileReader = new FileReader();
  const obs$: Observable<HttpEvent<unknown>> = new Observable((observer) => {
    reader.onload = () => {
      observer.error(new HttpErrorResponse({
        headers: err.headers,
        status: err.status,
        statusText: err.statusText,
        url: err.url,
        error: isJson(reader.result as string || null) ? JSON.parse(reader.result as string || null) : {}
      }));
    };
    reader.onerror = () => observer.error(err);
  });
  reader.readAsText(err.error);
  return obs$;
};

const isJson = (value: string): boolean => {
  try {
    JSON.parse(value);
    return true;
  } catch(e) {
    return false;
  }
};
