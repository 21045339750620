import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AbstractModal, ModalViewerService } from '@nesea/ngx-ui-kit/modal';
import { CustomValidators, FileType, FileUtils, IModalInput, IModalOutput, ITableAction, ITableColumn, ITranslationParams, MimeType } from '@nesea/ngx-ui-kit/shared';
import { ToastService } from '@nesea/ngx-ui-kit/toast';
import { ConfirmModalComponent, IConfirmModalOutput } from '@shared/components/confirm-modal/confirm-modal.component';
import {
  IAttachmentTimesheet,
  IDocumentHistoryTimesheet,
  IDocumentRequestTimesheet
} from '@shared/models/interfaces/timesheet.interface';
import { DocumentsService } from '@shared/services/documents.service';
import { TimesheetService } from '@shared/services/timesheet.service';
import { filter, map, switchMap, take, tap } from 'rxjs';
import {
  FilePreviewModalComponent,
  IFilePreviewModalInput,
  IFilePreviewModalOutput
} from '@shared/modals/file-preview-modal/file-preview-modal.component';
import { FileSaverService } from '@nesea/ngx-ui-kit/file-saver';
import { FormConfigService } from '@core/services/form-config.service';

export interface ITimesheetSendUploadModalInput extends IModalInput {
  message?: string;
  translateParams?: ITranslationParams;
  timesheetId: number;
  month: number;
  year: number;
  documents: IDocumentHistoryTimesheet[];
}
export interface ITimesheetSendUploadModalOutput extends IModalOutput {
  outcome: boolean,
  note?: string,
  files?: IAttachmentTimesheet[];
}

type AttachmentTimesheet = Partial<IDocumentHistoryTimesheet> & { uploadDate: number, file?: File };

@Component({
    selector: 'nsf-timesheet-send-upload-modal',
    templateUrl: './timesheet-send-upload-modal.component.html',
    styleUrl: './timesheet-send-upload-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TimesheetSendUploadModalComponent extends AbstractModal<ITimesheetSendUploadModalInput, ITimesheetSendUploadModalOutput>{

  note: string;
  form: FormGroup;

  columns: ITableColumn<AttachmentTimesheet>[] = [];
  actions: ITableAction<AttachmentTimesheet>[] = [];

  items: AttachmentTimesheet[] = [];

  acceptedFileTypes: FileType[] = [
    '.pdf',
    '.jpg',
    '.jpeg',
    '.png'
  ];

  showAlert: boolean = false;

  private _cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  private _toastService: ToastService = inject(ToastService);
  private _formConfigService: FormConfigService = inject(FormConfigService);
  private _modalViewerService: ModalViewerService = inject(ModalViewerService);
  private _fileSaverService: FileSaverService = inject(FileSaverService);
  private _timesheetService: TimesheetService = inject(TimesheetService);
  private _documentsService: DocumentsService = inject(DocumentsService);


  constructor(
    protected override modalViewerService: ModalViewerService,
    private _fb: FormBuilder,
  ) {
    super(modalViewerService);
  }

  get acceptedExtensions(): string {
    return this.acceptedFileTypes.map(type => type.replace(/\./g, "").toLocaleUpperCase()).join(', ');
  }

  get maxSize(): string {
    return FileUtils.getMaxFileSize(this._formConfigService.config.maxFileSize);
  }

  get showAttachments(): boolean {
    return !!this.form?.get('attachments').value;
  }

  override onInit(): void {
    this.form = this._fb.group({
      attachments: this._fb.control(!!this.data.documents?.length),
      files: this._fb.control(null, [CustomValidators.fileExtension(this.acceptedFileTypes, true), CustomValidators.fileSize(this._formConfigService.config.maxFileSize)])
    });

    this.items = (this.data.documents || []).map(item => ({
      id: item.id,
      nomeFile: item.nomeFile,
      uploadDate: new Date(item.dataInserimento).getTime()
    }));

    this.columns = [
      { id: 'name', label: 'LABEL.NAME.TEXT', property: 'nomeFile' }
    ];

    this.actions = [
      { id: 'delete-action', label: 'ACTION.DELETE', icon: 'trash', onClick: (item: AttachmentTimesheet) => this._onDelete(item) },
      { id: 'preview-action', label: 'ACTION.PREVIEW', icon: 'eye', onClick: (item: AttachmentTimesheet) => this._onPreview(item) },
      { id: 'download-action', label: 'ACTION.DOWNLOAD', icon: 'download', onClick: (item: AttachmentTimesheet) => this._onDownload(item) },
    ];
  }

  override onDestroy(): void { }


  onAttachmentsChange(): void {
    this.form.get('files').reset();
    this.showAlert = false;
  }

  onFileChange(value: File[]): void {
    this.showAlert = false;

    if(!!value?.length) {
      const mimeTypes: MimeType[] = ['image/png', 'image/jpeg', 'application/pdf'];
      let allowedFiles: AttachmentTimesheet[] = value
        .filter(file => mimeTypes.includes(file.type as MimeType))
        .map(file => ({
          nomeFile: file.name,
          file,
          uploadDate: new Date().getTime()
        }));

      if(allowedFiles.some(({ nomeFile }) => this.items.some(item => item.nomeFile === nomeFile))) {
        this.showAlert = true;
      }

      if(this.showAlert) {
        allowedFiles = allowedFiles.filter(({ file }) => !this.items.some(({ nomeFile }) => file.name === nomeFile))
      }

      this.items = [...allowedFiles, ...this.items];

      if(!this.form.get('files').hasError('fileType')) {
        this.form.get('files').reset();
      }
    }
  }

  onConfirm(): void {
    if(!this.form.valid) {
      this.form.markAllAsTouched();
    }

    if(!!this.items.some(({ file }) => file)) {
      const files: File[] = this.items
        .filter(({ file }) => !!file)
        .map(({ file }) => file);

      FileUtils.readFilesByteArray(files, true).pipe(
        tap<{ file: File, byteArray: Uint8Array }[]>(result => {
          const timesheetAttachments: IAttachmentTimesheet[] = result.map(({ file, byteArray }) => ({
            name: file.name,
            byteArray: Array.from(byteArray),
            size: file.size,
            extension: file.name.split('.')[1]
          }));

          this.close({ outcome: true, files: timesheetAttachments });
        })
      ).subscribe();
    } else {
      this.close({ outcome: true });
    }
  }


  onAbort(): void {
    this.close({ outcome: false });
  }

  private _onDelete(item: AttachmentTimesheet): void {
    this.showAlert = false;

    if(!item.file) {
      const request: IDocumentRequestTimesheet = {
        nomeFile: item.nomeFile,
        id: item.id,
        mese: this.data.month + 1,
        anno: this.data.year
      };

      this.modalViewerService.open(ConfirmModalComponent, {
        size: 'md',
        titleUppercase: true,
        title: 'MODAL.CONFIRM.TITLE',
        data: { message: 'MODAL.CONFIRM.MESSAGE', showNote: false }
      }).pipe(
        take(1),
        filter((output: IConfirmModalOutput) => !!output?.outcome),
        map(({ note }) => note),
        switchMap(note =>  this._documentsService.deleteTimesheetDocument(request).pipe(
          take(1),
          tap(() => this._toastService.showSuccess({
            title: 'DOCUMENTS.NOTIFICATION.SUCCESS.DELETION.TITLE',
            message: 'DOCUMENTS.NOTIFICATION.SUCCESS.DELETION.MESSAGE'
          }))
        )),
        switchMap(() => this._timesheetService.getTimesheetDocuments(this.data.timesheetId).pipe(
          take(1),
          tap(items => {
            this.items = (items || []).map(item => ({
              id: item.id,
              nomeFile: item.nomeFile,
              uploadDate: new Date(item.dataInserimento).getTime()
            }));

            this._cdr.markForCheck();
          })
        ))
      ).subscribe();
    } else {
      this.items = this.items.filter(({ nomeFile, file }) => !file || nomeFile !== item.nomeFile);
      this._cdr.markForCheck();
    }
  }

  private _onPreview(item: AttachmentTimesheet): void {
    if(!item.file) {
      const request: IDocumentRequestTimesheet = {
        nomeFile: item.nomeFile,
        mese: this.data.month + 1,
        anno: this.data.year,
        id: item.id
      };

      this._documentsService.previewTimesheetDocument(request).pipe(
        take(1)
      ).subscribe();
    } else {
      this._modalViewerService.open<IFilePreviewModalInput, IFilePreviewModalOutput>(FilePreviewModalComponent, {
        size: 'xl',
        data: { file: item.file },
        title: 'DOCUMENTS.MODAL.PREVIEW.TITLE',
        translateParams: {
          fileName: item.nomeFile
        }
      }).pipe(
        take(1)
      ).subscribe();
    }
  }

  private _onDownload(item: AttachmentTimesheet): void {
    if(!item.file) {
      const request: IDocumentRequestTimesheet = {
        nomeFile: item.nomeFile,
        mese: this.data.month + 1,
        anno: this.data.year,
        id: item.id
      };

      this._documentsService.downloadTimesheetDocument(request).pipe(
        take(1)
      ).subscribe();
    } else {
      this._fileSaverService.save(item.file, item.nomeFile);
    }
  }

}
