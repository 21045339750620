import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@core/tokens';

const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token';
const ID_TOKEN = 'id_token';
const PKCE_VERIFIER = 'PKCE_verifier';
const NONCE = 'nonce';
const LANGUAGE = 'language';

const KEYS = [ACCESS_TOKEN, REFRESH_TOKEN, ID_TOKEN, PKCE_VERIFIER, NONCE];

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    @Inject(WINDOW) private _window: Window
  ) { }

  get accessToken(): string {
    return this._window.sessionStorage.getItem(ACCESS_TOKEN) || '';
  }

  get refreshToken(): string {
    return this._window.sessionStorage.getItem(REFRESH_TOKEN) || '';
  }

  get idToken(): string {
    return this._window.sessionStorage.getItem(ID_TOKEN) || '';
  }

  get pkceVerifier(): string {
    return this._window.sessionStorage.getItem(PKCE_VERIFIER) || '';
  }

  get nonce(): string {
    return this._window.sessionStorage.getItem(NONCE) || '';
  }

  get hasToken(): boolean {
    return !!this.accessToken && !!this.refreshToken && !!this.idToken;
  }

  get language(): string {
    return this._window.localStorage.getItem(LANGUAGE) || '';
  }

  setAccessToken(value: string): void {
    this._window.sessionStorage.setItem(ACCESS_TOKEN, value);
  }

  setRefreshToken(value: string): void {
    this._window.sessionStorage.setItem(REFRESH_TOKEN, value);
  }

  setIdToken(value: string): void {
    this._window.sessionStorage.setItem(ID_TOKEN, value);
  }

  setPkceVerifier(value: string): void {
    this._window.sessionStorage.setItem(PKCE_VERIFIER, value);
  }

  setNonce(value: string): void {
    this._window.sessionStorage.setItem(NONCE, value);
  }

  setLanguage(value: string): void {
    this._window.localStorage.setItem(LANGUAGE, value);
  }

  clear(): void {
    KEYS.forEach(key => this._window.sessionStorage.removeItem(key));
  }
}
