import { AfterViewInit, ChangeDetectionStrategy, Component, computed, effect, inject, input, InputSignal, OnDestroy, OnInit, Signal, signal, ViewChild, WritableSignal } from '@angular/core';
import { CalendarOptions, ViewApi } from '@fullcalendar/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import itLocale from '@fullcalendar/core/locales/it';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import { TimesheetService } from '@shared/services/timesheet.service';
import { catchError, filter, forkJoin, iif, map, of, switchMap, take, tap, throwError } from 'rxjs';
import { IHoliday } from '@shared/models/interfaces/holiday.interface';
import { IAbsenceTimesheet, IDocumentHistoryTimesheet, IOrderTimesheet, IOvertimeTimesheet, ITimesheet, IWorkingDayTimesheet } from '@shared/models/interfaces/timesheet.interface';
import { Tooltip } from 'bootstrap';
import { ModalViewerService } from '@nesea/ngx-ui-kit/modal';
import { TimesheetSearchModalComponent } from '@shared/modals/timesheet-search-modal/timesheet-search-modal.component';
import { DateUtils } from '@shared/utils/date.utils';
import { ITimesheetSummaryModalInput, ITimesheetSummaryModalOutput, TimesheetSummaryModalComponent } from '@shared/modals/timesheet-summary-modal/timesheet-summary-modal.component';
import { EventImpl } from '@fullcalendar/core/internal';
import { OrdersService } from '@shared/services/orders.service';
import { TimesheetStatusEnum, TimesheetStatusIdEnum } from '@shared/enums/timesheet-status.enum';
import { ToastService } from '@nesea/ngx-ui-kit/toast';
import { ITimesheetOrderUpdateModalInput, ITimesheetOrderUpdateModalOutput, TimesheetOrderUpdateModalComponent } from '@shared/modals/timesheet-order-update-modal/timesheet-order-update-modal.component';
import { IWeekWorktime } from '@shared/models/interfaces/week-worktime.interface';
import { PermissionEnum } from '@core/enums/permission.enum';
import { ITypologyIT } from '@shared/models/interfaces/typology.interface';
import { ITimesheetSendUploadModalOutput, TimesheetSendUploadModalComponent } from '@shared/modals/timesheet-send-upload-modal/timesheet-send-upload-modal.component';
import { translate } from '@jsverse/transloco';
import { ConfirmModalComponent, IConfirmModalOutput } from '@shared/components/confirm-modal/confirm-modal.component';
import { ITimesheetHistory } from '@shared/models/interfaces/timesheet-history.interface';
import { IOrder } from '@shared/models/interfaces/order.interface';
import { ITimesheetOrderDeleteModalInput, ITimesheetOrderDeleteModalOutput, TimesheetOrderDeleteModalComponent } from '@shared/modals/timesheet-order-delete-modal/timesheet-order-delete-modal.component';
import { SpinnerService } from '@nesea/ngx-ui-kit/spinner';
import { toSignal } from '@angular/core/rxjs-interop';
import { TimesheetUtils } from '@shared/utils/timesheet.utils';
import { IUser } from '@core/models/interfaces/user.interface';
import { WINDOW } from '@core/tokens';

enum TimesheetOrderEnum {
  WORK,
  OVERTIME,
  ABSENCE
}

@Component({
  selector: 'nsf-timesheet',
  templateUrl: './timesheet.component.html',
  styleUrl: './timesheet.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class TimesheetComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('fullCalendar') fullCalendar: FullCalendarComponent;

  userData: InputSignal<IUser> = input.required<IUser>();
  // TODO: session user permissions
  userPermissions: InputSignal<PermissionEnum[]> = input.required<PermissionEnum[]>();
  userWorktime: InputSignal<IWeekWorktime[]> = input<IWeekWorktime[]>();
  management: InputSignal<boolean> = input.required<boolean>();
  timesheetData: InputSignal<ITimesheet> = input<ITimesheet>();

  fullCalendarOptions: WritableSignal<CalendarOptions> = signal<CalendarOptions>({
    plugins: [
      dayGridPlugin,
      interactionPlugin,
      bootstrap5Plugin
    ],
    initialView: 'dayGridMonth',
    locale: itLocale,
    themeSystem: 'bootstrap5',
    weekends: false,
    headerToolbar: false,
    dayHeaderClassNames: 'nsf-timesheet-header-cell',
    dayCellClassNames: 'nsf-timesheet-day-cell',
    eventClassNames: 'nsf-timesheet-event',
    eventOrder: 'type, name',
    eventDidMount: ({ el, event }): void => this._onEventMounted(el, event),
    dateClick: ({ date }): void => this._onDateClick(date),
    eventClick: ({ event }): void => this._onEventClick(event),
    eventAllow: () => false,
    windowResize: ({ view }) => this._onWindowResize(view)
  });

  showHolidays: Signal<boolean> = computed(() => !!this.fullCalendarOptions().weekends);

  timesheet: WritableSignal<ITimesheet> = signal(null);
  timesheetStatus: Signal<ITypologyIT<TimesheetStatusEnum ,TimesheetStatusIdEnum>> = computed(() => this.timesheet()?.stato);

  isTimesheetError: WritableSignal<boolean> = signal(true);
  isTimesheetDraft: Signal<boolean> = computed(() => !this.timesheetStatus() || this.timesheetStatus()?.code === TimesheetStatusEnum.DRAFT);
  isTimesheetSent: Signal<boolean> = computed(() => this.timesheetStatus()?.code === TimesheetStatusEnum.SENT);
  isTimesheetApproved: Signal<boolean> = computed(() => this.timesheetStatus()?.code === TimesheetStatusEnum.APPROVED);
  isTimesheetRejected: Signal<boolean> = computed(() => this.timesheetStatus()?.code === TimesheetStatusEnum.REJECTED);

  currentMonthTitle: WritableSignal<string> = signal(null);
  previousDate: WritableSignal<Date> = signal(DateUtils.firstDayOfCurrentMonth());
  currentDate: WritableSignal<Date> = signal(DateUtils.firstDayOfCurrentMonth());

  currentYear: Signal<number> = computed(() => this.currentDate().getFullYear());
  previousYear: Signal<number> = computed(() => this.previousDate().getFullYear());
  currentMonth: Signal<number> = computed(() => this.currentDate().getMonth() + 1);
  nextMonthDisabled: Signal<boolean> = computed(() => {
    const currentMonth: number = (this.currentDate()?.getMonth() || 0);
    const currentYear: number = this.currentDate()?.getFullYear();
    const nextDate: Date = new Date(currentYear, currentMonth + 1, 1);
    return nextDate.getTime() > DateUtils.lastDayOfMonth(new Date()).getTime();
  });

  hasApprovePermission: Signal<boolean> = computed(() => this.userPermissions().includes(PermissionEnum.APPROVE_TIMESHEETS));
  hasRejectPermission: Signal<boolean> = computed(() => this.userPermissions().includes(PermissionEnum.REJECT_TIMESHEETS));

  private _window: Window = inject(WINDOW);
  private _modalViewerService: ModalViewerService = inject(ModalViewerService);
  private _toastService: ToastService = inject(ToastService);
  private _timesheetService: TimesheetService = inject(TimesheetService);
  private _ordersService: OrdersService = inject(OrdersService);
  private _spinnerService: SpinnerService = inject(SpinnerService);

  private _userHolidays: IHoliday[] = [];

  isSpinnerLoading: Signal<boolean> = toSignal(this._spinnerService.loading$);

  constructor() {
    effect(() => {
      if(!!this.timesheetData()) {
        const date: Date = new Date(this.timesheetData().anno, this.timesheetData().mese - 1, 1);
        this.currentDate.set(date);
        this.previousDate.set(date);
      }
    });
  }

  ngOnInit(): void { }

  ngOnDestroy(): void { }

  ngAfterViewInit(): void {
    if(!!this.management()) {
      this._getUserTimesheet();
    } else {
      this._getTimesheet(true);
    }
  }

  onWorkingweekClick(): void {
    this.fullCalendarOptions.update(currentOptions => ({
      ...currentOptions,
      weekends: !currentOptions.weekends
    }));
  }

  onMonthSummaryClick(): void {
    const monthHolidays: IHoliday[] = this._userHolidays.filter(({ mese }) => mese === this.currentDate().getMonth() + 1);
    const workingDays: IWorkingDayTimesheet<IOrderTimesheet>[] = this.timesheet()?.giornoCommessaTimesheet || [];
    const overtimeDays: IWorkingDayTimesheet<IOvertimeTimesheet>[] = this.timesheet()?.giornoStraordinario || [];
    const absences: IAbsenceTimesheet[] = this.timesheet()?.giornoAssenze || [];

    const startDate: Date = new Date(this.currentDate().getFullYear(), this.currentDate().getMonth(), 1);
    const endDate: Date = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);

    this._ordersService.getUserActivations(this.userData()?.id, startDate.getTime(), endDate.getTime()).pipe(
      take(1),
      catchError(() => of([] as IOrder[])),
      switchMap(orders => iif(
        () => !!this.timesheet()?.idTimesheet,
        forkJoin([
          this._timesheetService.getTimesheetHistory(this.timesheet()?.idTimesheet).pipe(
            take(1),
            catchError(() => of([] as ITimesheetHistory[])),
          ),
          this._timesheetService.getTimesheetDocuments(this.timesheet()?.idTimesheet).pipe(
            take(1),
            catchError(() => of([] as IDocumentHistoryTimesheet[])),
          )
        ]).pipe(
          map(([history, documents]) => ({ orders, history, documents })),
        ),
        of({ orders, history: [] as ITimesheetHistory[], documents: [] as IDocumentHistoryTimesheet[] }),
      )),
      switchMap(({ orders, history, documents }) => this._modalViewerService.open<ITimesheetSummaryModalInput, ITimesheetSummaryModalOutput>(TimesheetSummaryModalComponent, {
        title: 'TIMESHEET.MODAL.SUMMARY.TITLE',
        translateParams: { currentMonth: this.currentMonthTitle() },
        size: 'xl',
        data: {
          timesheetId: this.timesheet()?.idTimesheet,
          management: this.management(),
          currentMonth: this.currentDate().getMonth(),
          currentYear: this.currentDate().getFullYear(),
          workTime: this.userWorktime(),
          holidays: [...monthHolidays],
          orders,
          workingDays,
          overtimeDays,
          absences,
          history,
          mealVouchers: this.timesheet()?.buoniPasto || 0,
          documents
        },
        sidebar: true
      }).pipe(
        take(1),
      ))
    ).subscribe();
  }

  onAddOrdersClick(): void {
    const monthHolidays: IHoliday[] = this._userHolidays.filter(({ mese }) => mese === this.currentDate().getMonth() + 1);
    const monthAbsences: IAbsenceTimesheet[] = this.timesheet()?.giornoAssenze || [];
    const monthWorkingDays: IWorkingDayTimesheet<IOrderTimesheet>[] = this.timesheet()?.giornoCommessaTimesheet || [];

    this._openOrderUpdateModal(monthHolidays, monthWorkingDays, monthAbsences);
  }

  onRemoveOrdersClick(): void {
    const monthHolidays: IHoliday[] = this._userHolidays.filter(({ mese }) => mese === this.currentDate().getMonth() + 1);
    const monthAbsences: IAbsenceTimesheet[] = this.timesheet()?.giornoAssenze || [];
    const monthWorkingDays: IWorkingDayTimesheet<IOrderTimesheet>[] = this.timesheet()?.giornoCommessaTimesheet || [];

    this._openOrderDeleteModal(monthHolidays, monthWorkingDays, monthAbsences);
  }

  onSendTimesheetClick(): void {
    this._timesheetService.getTimesheetDocuments(this.timesheet().idTimesheet).pipe(
      take(1),
      switchMap(( documents ) =>
        this._modalViewerService.open(TimesheetSendUploadModalComponent, {
          size: 'lg',
          title: 'TIMESHEET.MODAL.SEND.TITLE',
          data: {
            message: 'TIMESHEET.MODAL.SEND.MESSAGE',
            translateParams: { currentMonth: this.currentMonthTitle() },
            timesheetId: this.timesheet().idTimesheet,
            month: this.currentDate().getMonth(),
            year: this.currentDate().getFullYear(),
            documents
          }
        }).pipe(
          take(1),
          filter((output: ITimesheetSendUploadModalOutput) => !!output?.outcome),
          switchMap((output: ITimesheetSendUploadModalOutput) =>
            this._timesheetService.sendTimesheet(
              this.userData()?.id,
              this.currentDate().getMonth() + 1,
              this.currentDate().getFullYear(),
              output.files
            ).pipe(
              take(1),
              catchError(err => {
                this._getTimesheet();
                return throwError(() => err);
              }),
              tap(() => {
                this._toastService.showSuccess({
                  title: 'TIMESHEET.NOTIFICATION.SUCCESS.SEND.TITLE',
                  message: 'TIMESHEET.NOTIFICATION.SUCCESS.SEND.MESSAGE'
                });
                this._getTimesheet();
              })
            )
          )
        )
      )
    ).subscribe();
  }

  onApproveTimesheetClick(): void {
    this._modalViewerService.open(ConfirmModalComponent, {
      size: 'lg',
      title: 'TIMESHEET.MODAL.APPROVE.TITLE',
      data: { message: 'TIMESHEET.MODAL.APPROVE.MESSAGE', translateParams: { currentMonth: this.currentMonthTitle() }, showNote: true }
    }).pipe(
      take(1),
      filter((output: IConfirmModalOutput) => !!output?.outcome),
      map(({ note }) => note),
      switchMap(note => this._timesheetService.approveTimesheet(this.timesheet()?.idTimesheet, note).pipe(
        take(1),
        tap(() => {
          this._toastService.showSuccess({
            title: 'TIMESHEET.NOTIFICATION.SUCCESS.APPROVE.TITLE',
            message: 'TIMESHEET.NOTIFICATION.SUCCESS.APPROVE.MESSAGE'
          });

          this._getTimesheet();
        })
      ))
    ).subscribe();
  }

  onRejectTimesheetClick(): void {
    this._modalViewerService.open(ConfirmModalComponent, {
      size: 'lg',
      title: 'TIMESHEET.MODAL.REJECT.TITLE',
      data: { message: 'TIMESHEET.MODAL.REJECT.MESSAGE', translateParams: { currentMonth: this.currentMonthTitle() }, showNote: true }
    }).pipe(
      take(1),
      filter((output: IConfirmModalOutput) => !!output?.outcome),
      map(({ note }) => note),
      switchMap(note => this._timesheetService.rejectTimesheet(this.timesheet()?.idTimesheet, note).pipe(
        take(1),
        tap(() => {
          this._toastService.showSuccess({
            title: 'TIMESHEET.NOTIFICATION.SUCCESS.REJECT.TITLE',
            message: 'TIMESHEET.NOTIFICATION.SUCCESS.REJECT.MESSAGE'
          });

          this._getTimesheet();
        })
      ))
    ).subscribe();
  }

  onSearchClick(): void {
    this._modalViewerService.open(TimesheetSearchModalComponent, { title: 'TIMESHEET.MODAL.SEARCH.TITLE', size: 'md', data: { date: this.currentDate() } }).pipe(
      take(1),
      filter(output => !!output?.outcome),
      filter(({ date }) => !DateUtils.sameDate(date, this.currentDate())),
      tap(({ date }) => {
        this.fullCalendar.getApi().gotoDate(date);

        this._updateCurrentDate();
        this._getTimesheet();
      })
    ).subscribe();
  }

  onPreviousMonthClick(): void {
    this.fullCalendar.getApi().prev();

    this._updateCurrentDate();
    this._getTimesheet();
  }

  onNextMonthClick(): void {
    this.fullCalendar.getApi().next();

    this._updateCurrentDate();
    this._getTimesheet();
  }

  private _updateCurrentDate(): void {
    this.currentDate.update(date => {
      this.previousDate.set(date);
      return this.fullCalendar.getApi().getDate();
    });
  }

  private _updateCurrentMonth(): void {
    this.currentMonthTitle.set(this.fullCalendar.getApi().getCurrentData().viewTitle);
  }

  private _getUserTimesheet(): void {
    this.isTimesheetError.set(false);
    this.fullCalendar.getApi().gotoDate(DateUtils.firstDayOfMonth(new Date(this.timesheetData().anno, this.timesheetData().mese - 1, 1)));
    this._updateCurrentDate();

    this._timesheetService.getUserHolidays(this.userData()?.id, this.timesheetData().anno).pipe(
      take(1),
      catchError(() => of([] as IHoliday[])),
      tap(holidays => this._userHolidays = [...holidays]),
      tap(holidays => {
        this._updateTimesheet(this.timesheetData(), holidays);
        this._updateCurrentMonth();
      })
    ).subscribe();
  }

  private _getTimesheet(firstCall: boolean = false): void {
    const currentMonth: number = this.currentMonth();
    const currentYear: number = this.currentYear();
    const previousYear: number = this.previousYear();

    this._timesheetService.getTimesheet(this.userData()?.id, currentMonth, currentYear).pipe(
      take(1),
      tap(() => this.isTimesheetError.set(false)),
      catchError(() => {
        this.isTimesheetError.set(true);
        return of(undefined);
      }),
      switchMap(timesheet => iif(
        () => firstCall || currentYear !== previousYear,
        this._timesheetService.getUserHolidays(this.userData()?.id, currentYear).pipe(
          take(1),
          catchError(() => of([])),
          tap(holidays => this._userHolidays = [...holidays]),
          map(holidays => ({ timesheet, holidays }))
        ),
        of(this._userHolidays).pipe(
          map(holidays => ({ timesheet, holidays }))
        )
      )),
      tap(({ timesheet, holidays }) => {
        this._updateTimesheet(timesheet, holidays);
        this._updateCurrentMonth();
      })
    ).subscribe();
  }

  private _updateTimesheet(timesheet: ITimesheet, holidays: IHoliday[]): void {
    this.fullCalendar.getApi().removeAllEvents();
    this.timesheet.set(timesheet);

    this._addHolidays(holidays);
    this._addOrders(timesheet?.giornoCommessaTimesheet);
    this._addOvertimes(timesheet?.giornoStraordinario);
    this._addAbsences(timesheet?.giornoAssenze);
    this._addRemainingHours(timesheet?.giornoCommessaTimesheet, timesheet?.giornoAssenze);
  }

  private _addRemainingHours(workingDays: IWorkingDayTimesheet<IOrderTimesheet>[], absences: IAbsenceTimesheet[]): void {
    const firstDayOfMonth: Date = DateUtils.firstDayOfMonth(this.currentDate());
    const lastDayOfMonth: Date = DateUtils.lastDayOfMonth(this.currentDate());
    const monthHolidays: IHoliday[] = this._userHolidays.filter(({ mese }) => mese === this.currentDate().getMonth() + 1);

    DateUtils.daysBetween(firstDayOfMonth, lastDayOfMonth)
      .filter(({ dayOfMonth }) => !monthHolidays.some(({ giorno }) => giorno === dayOfMonth))
      .map(({ dayOfMonth, dayOfWeek, date }) => {
        const workTime: IWeekWorktime = TimesheetUtils.getWorktimeByDate(this.userWorktime(), date);
        const maxHours: number = workTime?.[dayOfWeek] || 0;

        const output: { date: Date, remainingHours: number } = {
          date,
          remainingHours: 0
        };

        if(maxHours > 0) {
          const workingDay: IWorkingDayTimesheet<IOrderTimesheet> = (workingDays || [])
            .find(({ giorno }) => giorno === dayOfMonth);
          const workedHours: number = !!workingDay ? workingDay.commesseOreTimesheet
            .reduce((output, current) => output + current.oreLavorate, 0) : 0;
          const absenceHours: number = (absences || [])
            .filter(({ giorno }) => giorno === dayOfMonth)
            .reduce((output, current) => output + current.ore, 0);

          output.remainingHours = (maxHours - (workedHours + absenceHours));
        }

        return output;
      })
      .filter(({ remainingHours }) => remainingHours > 0)
      .forEach(({ date, remainingHours }) => {
        this.fullCalendar.getApi().addEvent({
          title: this._window.innerWidth < 768 ? `${TimesheetUtils.parseHours(remainingHours)}`: `${translate('LABEL.RESIDUAL_HOURS.TEXT')}: ${TimesheetUtils.parseHours(remainingHours)}` ,
          date,
          classNames: 'nsf-timesheet-remaining-hours',
          allDay: true,
          editable: false,
          display: 'background'
        });
      });
  }

  private _addHolidays(holidays: IHoliday[]): void {
    (holidays || []).forEach(({ giorno: day, mese: month, nome: name }) => {
      const date: Date = new Date(this.currentDate().getFullYear(), month - 1, day);
      this.fullCalendar.getApi().addEvent({
        title: name?.toLocaleUpperCase(),
        date,
        classNames: 'nsf-timesheet-holiday-event',
        allDay: true,
        display: 'background',
      });
    });
  }

  private _addOrders(workingDays: IWorkingDayTimesheet<IOrderTimesheet>[]): void {
    (workingDays || []).forEach(({ giorno: day, commesseOreTimesheet: orders }) => {
      (orders || []).forEach(order => {
        const hours: number = Math.floor(order.oreLavorate);
        const minutes: number = Math.round((order.oreLavorate - hours) * 60);
        const title: string = order.oreLavorate > 0 ? `${hours}h : ${minutes.toString().padStart(2, '0')}m - ${order.name}` : `${order.name}`;

        this.fullCalendar.getApi().addEvent({
          id: `${day}_${order.id}`,
          title,
          date: new Date(this.currentDate().getFullYear(), this.currentDate().getMonth(), day),
          allDay: true,
          editable: !this.management(),
          classNames: 'nsf-timesheet-order-event',
          extendedProps: { ...order, type: TimesheetOrderEnum.WORK },
        });
      });
    });
  }

  private _addOvertimes(overtimes: IWorkingDayTimesheet<IOvertimeTimesheet>[]) {
    (overtimes || []).forEach(({ giorno: day, commesseOreTimesheet: orders }) => {
      (orders || []).forEach(order => {
        const hours: number = Math.floor(order.oreLavorate);
        const minutes: number = Math.round((order.oreLavorate - hours) * 60);
        const title: string = order.oreLavorate > 0 ? `${hours}h : ${minutes.toString().padStart(2, '0')}m - ${order.name} (${order.tipo})` : `${order.name} (${order.tipo})`;

        this.fullCalendar.getApi().addEvent({
          id: `${day}_${order.id}`,
          title,
          date: new Date(this.currentDate().getFullYear(), this.currentDate().getMonth(), day),
          allDay: true,
          editable: false,
          classNames: 'nsf-timesheet-overtime-event',
          extendedProps: { ...order, type: TimesheetOrderEnum.OVERTIME }
        });
      });
    });
  }

  private _addAbsences(absences: IAbsenceTimesheet[]): void {
    const extendedAbsences: (IAbsenceTimesheet & { id: number })[] = [];
    (absences || []).forEach(absence => {
      if(extendedAbsences.find(({ giorno: day }) => day === absence.giorno)) {
        const last = extendedAbsences.reduce((prev, current) => {
          return (!!prev && prev.id > current.id) ? prev : current;
        });
        extendedAbsences.push({
          ...absence,
          id: last.id + 1
        });
      } else {
        extendedAbsences.push({
          ...absence,
          id: 0
        });
      }
    });

    extendedAbsences.forEach(({ id, giorno: day, tipo: type, ore: absenceHours }) => {
      const hours: number = Math.floor(absenceHours);
      const minutes: number = Math.round((absenceHours - hours) * 60);
      const title: string = absenceHours > 0 ? `${hours}h : ${minutes.toString().padStart(2, '0')}m - ${type}` : `${type}`;

      this.fullCalendar.getApi().addEvent({
        id: `${day}_${id}`,
        title,
        date: new Date(this.currentDate().getFullYear(), this.currentDate().getMonth(), day),
        allDay: true,
        editable: false,
        classNames: 'nsf-timesheet-absence-event',
        extendedProps: { name: type, type: TimesheetOrderEnum.ABSENCE }
      });
    });
  }

  private _onEventMounted(el: HTMLElement, event: EventImpl): void {
    if([TimesheetOrderEnum.WORK, TimesheetOrderEnum.OVERTIME, TimesheetOrderEnum.ABSENCE].includes(event.extendedProps['type'])) {
      const title: string = event.extendedProps['type'] === TimesheetOrderEnum.WORK ?
        `${event.extendedProps['name']} / ${event.extendedProps['clientName']}` :
        (event.extendedProps['type'] === TimesheetOrderEnum.OVERTIME ?
          `${event.extendedProps['name']} / ${event.extendedProps['clientName']} (${event.extendedProps['tipo']})` : `${event.extendedProps['name']} (${translate('MY_ABSENCES.ABSENCE.LABEL')})`);
      const tooltip = new Tooltip(el, {
        title,
        placement: 'top',
        trigger: 'hover',
        container: 'body',
        customClass: `nsf-timesheet-tooltip-${event.extendedProps['type'] === TimesheetOrderEnum.WORK ? 'order' : (event.extendedProps['type'] === TimesheetOrderEnum.ABSENCE ? 'absence' : 'overtime')}-event`
      });
    }
  }

  private _onDateClick(date: Date): void {
    if(!this.isTimesheetError() && !this.management() && this.isTimesheetDraft() && DateUtils.sameMonth(date, this.currentDate())) {
      const monthHolidays: IHoliday[] = this._userHolidays.filter(({ mese }) => mese === date.getMonth() + 1);
      const monthAbsences: IAbsenceTimesheet[] = this.timesheet()?.giornoAssenze || [];
      const dayAbsences: IAbsenceTimesheet[] = monthAbsences.filter(({ giorno }) => giorno === date.getDate());
      const totalAbsenceHours: number = dayAbsences.reduce((result, current) => result + current.ore, 0);
      const workTime: IWeekWorktime = TimesheetUtils.getWorktimeByDate(this.userWorktime(), date);
      const maxHours: number = workTime?.[date.getDay()] || 0;

      if(!maxHours) {
        this._toastService.showWarning({
          title: 'TIMESHEET.NOTIFICATION.WARNING.NO_WORKTIME.TITLE',
          message: 'TIMESHEET.NOTIFICATION.WARNING.NO_WORKTIME.MESSAGE',
          translateParams: { hours: maxHours }
        });
      } else if(this._userHolidays.some(({ giorno, mese }) => date.getDate() === giorno && mese === date.getMonth() + 1)) {
        // Festività nazionale
        this._toastService.showWarning({
          title: 'TIMESHEET.NOTIFICATION.WARNING.FESTIVITY.TITLE',
          message: 'TIMESHEET.NOTIFICATION.WARNING.FESTIVITY.MESSAGE'
        });
      } else if(date.getDay() === 0 || date.getDay() === 6) {
        // Sabati e domeniche
        this._toastService.showWarning({
          title: 'TIMESHEET.NOTIFICATION.WARNING.HOLIDAY.TITLE',
          message: 'TIMESHEET.NOTIFICATION.WARNING.HOLIDAY.MESSAGE'
        });
      } else {
        const monthWorkingDays: IWorkingDayTimesheet<IOrderTimesheet>[] = this.timesheet()?.giornoCommessaTimesheet || [];
        const orders: IOrderTimesheet[] = monthWorkingDays.find(({ giorno }) => giorno === date.getDate())?.commesseOreTimesheet || [];
        const totalOrderHours: number = orders
          .reduce((result, current) => result + current.oreLavorate, 0);
        const leftHours: number = maxHours - (totalOrderHours + totalAbsenceHours);

        if(leftHours > 0) {
          this._openOrderUpdateModal(monthHolidays, monthWorkingDays, monthAbsences, date);
        } else {
          // Raggiunto max numero ore ordinarie
          this._toastService.showWarning({
            title: 'TIMESHEET.NOTIFICATION.WARNING.EXCEEDED.TITLE',
            message: 'TIMESHEET.NOTIFICATION.WARNING.EXCEEDED.MESSAGE',
            translateParams: { hours: maxHours }
          });
        }
      }
    }
  }

  private _onEventClick({ start, startEditable, extendedProps }: EventImpl): void {
    if(!this.isTimesheetError() && !this.management() && this.isTimesheetDraft() && DateUtils.sameMonth(start, this.currentDate())) {
      if(!!startEditable) {
        const monthHolidays: IHoliday[] = this._userHolidays.filter(({ mese }) => mese === start.getMonth() + 1);
        const monthWorkingDays: IWorkingDayTimesheet<IOrderTimesheet>[] = this.timesheet()?.giornoCommessaTimesheet || [];
        const monthAbsences: IAbsenceTimesheet[] = this.timesheet()?.giornoAssenze || [];
        const orderEdit: IOrderTimesheet = { ...extendedProps } as IOrderTimesheet;

        this._openOrderUpdateModal(monthHolidays, monthWorkingDays, monthAbsences, start, orderEdit);
      }
    }
  }

  private _onWindowResize(view: ViewApi): void {
    this._updateTimesheet(this.timesheet(), this._userHolidays);
  }

  private _openOrderUpdateModal(holidays: IHoliday[], workingDays: IWorkingDayTimesheet<IOrderTimesheet>[], absences: IAbsenceTimesheet[], startDate?: Date, order?: IOrderTimesheet): void {
    this._modalViewerService.open<ITimesheetOrderUpdateModalInput, ITimesheetOrderUpdateModalOutput>(TimesheetOrderUpdateModalComponent, {
      size: 'lg',
      title: 'TIMESHEET.MODAL.ORDER_UPDATE.TITLE',
      data: {
        userId: this.userData()?.id,
        currentMonth: this.currentDate().getMonth(),
        currentYear: this.currentDate().getFullYear(),
        workTime: this.userWorktime(),
        holidays,
        workingDays,
        absences,
        startDate,
        order
      },
      translateParams: { isEdit: !!order, name: order?.name }
    }).pipe(
      take(1),
      filter(output => !!output?.outcome),
      switchMap(({ remove, workingDays, excludedDays }) => iif(
        () => !!workingDays?.length,
        this._timesheetService.saveTimesheet(this.userData()?.id, this.currentDate().getMonth() + 1, this.currentDate().getFullYear(), workingDays).pipe(
          take(1),
          tap(() => {
            if(!excludedDays?.length) {
              if(remove) {
                this._toastService.showSuccess({
                  title: 'TIMESHEET.NOTIFICATION.SUCCESS.ORDER_DELETE.TITLE',
                  message: 'TIMESHEET.NOTIFICATION.SUCCESS.ORDER_DELETE.MESSAGE',
                  translateParams: { isOrder: true }
                });
              } else {
                this._toastService.showSuccess({
                  title: 'TIMESHEET.NOTIFICATION.SUCCESS.ORDER_UPDATE.TITLE',
                  message: 'TIMESHEET.NOTIFICATION.SUCCESS.ORDER_UPDATE.MESSAGE',
                  translateParams: { action: !order ? 'insert' : 'edit', isOrder: true }
                });
              }
            } else {
              let daysArray: string[] = excludedDays.map(day => DateUtils.formatDate(new Date(this.currentDate().getFullYear(), this.currentDate().getMonth(), day)));
              this._toastService.showWarning({
                title: 'TIMESHEET.NOTIFICATION.WARNING.ORDER_UPDATE.TITLE',
                message: 'TIMESHEET.NOTIFICATION.WARNING.ORDER_UPDATE.MESSAGE',
                translateParams: { days: daysArray.join(', '), isOrder: true }
              });
            }
            this._getTimesheet();
          })
        ),
        // TODO: Messaggio di warning (nessun giorno da inserire)
        of(undefined).pipe(
          tap(() => {
            let daysArray: string[] = excludedDays.map(day => DateUtils.formatDate(new Date(this.currentDate().getFullYear(), this.currentDate().getMonth(), day)));
            this._toastService.showWarning({
              title: 'TIMESHEET.NOTIFICATION.WARNING.ORDER_UPDATE.TITLE',
              message: 'TIMESHEET.NOTIFICATION.WARNING.ORDER_UPDATE.MESSAGE',
              translateParams: { days: daysArray.join(', '), isOrder: true }
            });
          })
        )
      ))
    ).subscribe();
  }

  private _openOrderDeleteModal(holidays: IHoliday[], workingDays: IWorkingDayTimesheet<IOrderTimesheet>[], absences: IAbsenceTimesheet[]): void {
    this._modalViewerService.open<ITimesheetOrderDeleteModalInput, ITimesheetOrderDeleteModalOutput>(TimesheetOrderDeleteModalComponent, {
      size: 'lg',
      title: 'TIMESHEET.MODAL.ORDER_DELETE.TITLE',
      data: {
        userId: this.userData()?.id,
        currentMonth: this.currentDate().getMonth(),
        currentYear: this.currentDate().getFullYear(),
        workTime: this.userWorktime(),
        holidays,
        workingDays,
        absences
      }
    }).pipe(
      take(1),
      filter(output => !!output?.outcome),
      tap(() => this._toastService.showSuccess({
        title: 'TIMESHEET.NOTIFICATION.SUCCESS.ORDER_DELETE.TITLE',
        message: 'TIMESHEET.NOTIFICATION.SUCCESS.ORDER_DELETE.MESSAGE',
        translateParams: { isOrder: true }
      })),
      tap(() => this._getTimesheet())
    ).subscribe();
  }

}
