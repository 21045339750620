import { ChangeDetectionStrategy, Component, computed, Signal } from '@angular/core';
import { AbstractModal, ModalViewerService } from '@nesea/ngx-ui-kit/modal';
import { IModalInput, IModalOutput } from '@nesea/ngx-ui-kit/shared';
import { IAbsenceTimesheet, IDocumentHistoryTimesheet, IOrderTimesheet, IOvertimeTimesheet, IWorkingDayTimesheet } from '@shared/models/interfaces/timesheet.interface';
import { IItem } from '@nesea/ngx-ui-kit/shared/models/interfaces/item.interface';
import { IOrder } from '@shared/models/interfaces/order.interface';
import { ITimesheetHistory } from '@shared/models/interfaces/timesheet-history.interface';
import { IWeekWorktime } from '@shared/models/interfaces/week-worktime.interface';
import { DateUtils } from '@shared/utils/date.utils';
import { IHoliday } from '@shared/models/interfaces/holiday.interface';
import { TimesheetUtils } from '@shared/utils/timesheet.utils';

export interface ITimesheetSummaryModalInput extends IModalInput {
  timesheetId: number;
  management: boolean;
  currentMonth: number;
  currentYear: number;
  workTime: IWeekWorktime[];
  holidays: IHoliday[];
  orders: IOrder[];
  workingDays: IWorkingDayTimesheet<IOrderTimesheet>[];
  overtimeDays: IWorkingDayTimesheet<IOvertimeTimesheet>[];
  absences: IAbsenceTimesheet[];
  history: ITimesheetHistory[];
  mealVouchers: number;
  documents: IDocumentHistoryTimesheet[];
}
export interface ITimesheetSummaryModalOutput extends IModalOutput { }

@Component({
    selector: 'nsf-timesheet-summary-modal',
    templateUrl: './timesheet-summary-modal.component.html',
    styleUrl: './timesheet-summary-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TimesheetSummaryModalComponent extends AbstractModal<ITimesheetSummaryModalInput, ITimesheetSummaryModalOutput> {

  actions: Partial<IItem>[] = [];
  activeActionId: string;
  activePanelId: string;

  remainingHours: number = 0;

  currentDate: Signal<Date> = computed(() => new Date(this.data.currentYear, this.data.currentMonth, 1));
  remainingParsedHours: Signal<string> = computed(() => {
    const hours = Math.floor(this.remainingHours);
    const minutes = Math.round((this.remainingHours - hours) * 60);
    const parsedHours: string = `${hours}`.padStart(2, '0');
    const parsedMinutes: string = `${minutes}`.padStart(2, '0');
    return `${parsedHours}h : ${parsedMinutes.toString().padStart(2, '0')}m`;
  });

  constructor(
    protected override modalViewerService: ModalViewerService
  ) {
    super(modalViewerService);
  }

  override onInit(): void {
    this.actions = [
      {
        id: 'activations',
        label: 'TIMESHEET.ACTION.ORDERS_SUMMARY',
        icon: 'info-circle',
        extra: {
          panelId: 'activations-panel'
        }
      },
      {
        id: 'orders',
        label: 'LABEL.ORDINARY.TEXT',
        icon: 'calendar',
        extra: {
          panelId: 'orders-panel'
        }
      },
      {
        id: 'overtimes',
        label: 'LABEL.OVERTIMES.TEXT',
        icon: 'calendar-plus',
        extra: {
          panelId: 'overtimes-panel'
        }
      },
      {
        id: 'absences',
        label: 'LABEL.ABSENCES.TEXT',
        icon: 'calendar-x',
        extra: {
          panelId: 'absences-panel'
        }
      },
      {
        id: 'documents',
        label: 'LABEL.DOCUMENTS.TEXT',
        icon: 'file-earmark',
        extra: {
          panelId: 'documents-panel'
        }
      },
      {
        id: 'history',
        label: 'LABEL.HISTORY.TEXT',
        icon: 'clock-history',
        extra: {
          panelId: 'history-panel'
        }
      }
    ];

    this.activeActionId = this.actions[0].id;
    this.activePanelId = this.actions[0].extra['panelId'] as string;

    this._updateRemainingHours();
  }
  override onDestroy(): void { }

  isActive(id: string): boolean {
    return this.activeActionId === id;
  }

  onActionClick(id: string, panelId: string): void {
    this.activeActionId = id;
    this.activePanelId = panelId;
  }

  private _updateRemainingHours(): void {
    const firstDateOfMonth: Date = DateUtils.firstDayOfMonth(this.currentDate());
    const lastDateOfMonth: Date = DateUtils.lastDayOfMonth(this.currentDate());

    let remainingHours: number = 0;
    DateUtils.daysBetween(firstDateOfMonth, lastDateOfMonth)
      .filter(({ dayOfMonth }) => !this.data.holidays.some(({ giorno }) => dayOfMonth === giorno))
      .filter(({ date, dayOfWeek }) => {
        const workTime: IWeekWorktime = TimesheetUtils.getWorktimeByDate(this.data.workTime, date);
        return workTime?.[dayOfWeek] > 0;
      })
      .forEach(({ dayOfMonth, dayOfWeek, date }) => {
        const workTime: IWeekWorktime = TimesheetUtils.getWorktimeByDate(this.data.workTime, date);

        const maxHours: number = workTime?.[dayOfWeek] || 0;

        const workingDay: IWorkingDayTimesheet<IOrderTimesheet> = this.data.workingDays.find(({ giorno }) => giorno === dayOfMonth);
        const workedHours: number = !!workingDay ? workingDay.commesseOreTimesheet.reduce((output, current) => output + current.oreLavorate, 0) : 0;
        const absenceHours: number = this.data.absences
          .filter(({ giorno }) => giorno === dayOfMonth)
          .reduce((output, current) => output + current.ore, 0);

        remainingHours += (maxHours - (workedHours + absenceHours));
    });

    this.remainingHours = remainingHours;
  }

}
