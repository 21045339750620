import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { Store } from '@ngrx/store';
import { authorize } from '@core/store/actions/core.actions';

@Component({
    selector: 'nsf-login',
    imports: [
        SharedModule
    ],
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent {

  constructor(
    private _store: Store
  ) {}

  onSubmit(): void {
    this._store.dispatch(authorize());
  }

}
