import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
const _c0 = ["*"];
const _c1 = (a0, a1) => ({
  "disabled": a0,
  "w-100": a1
});
function ButtonComponent_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "i");
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassMapInterpolate1("bi bi-", ctx_r0.icon, "");
  }
}
const _c2 = a0 => ({
  "disabled": a0
});
class ButtonComponent {
  constructor() {
    this.type = 'button';
    this.size = 'sm';
    this.color = 'primary';
    this.outlined = false;
    this.className = '';
    this.fullWidth = false;
  }
  get iconClass() {
    return !!this.icon ? `btn-icon ${!!this.outlined ? 'outline-' + (this.iconColor || '') : this.iconColor || ''}` : '';
  }
  onClick(event) {
    this.disabled && event.stopImmediatePropagation();
    this.disabled && event.stopPropagation();
  }
  static {
    this.ɵfac = function ButtonComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ButtonComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ButtonComponent,
      selectors: [["nsa-button"]],
      inputs: {
        nsaId: "nsaId",
        disabled: "disabled",
        type: "type",
        size: "size",
        color: "color",
        outlined: "outlined",
        className: "className",
        fullWidth: "fullWidth",
        icon: "icon",
        iconColor: "iconColor"
      },
      ngContentSelectors: _c0,
      decls: 5,
      vars: 13,
      consts: [[3, "click"], [3, "id", "type", "ngClass"], [3, "class"]],
      template: function ButtonComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵlistener("click", function ButtonComponent_Template_div_click_0_listener($event) {
            return ctx.onClick($event);
          });
          i0.ɵɵelementStart(1, "button", 1)(2, "span");
          i0.ɵɵprojection(3);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(4, ButtonComponent_Conditional_4_Template, 1, 3, "i", 2);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵclassMapInterpolate4("btn btn-nsa btn-", ctx.size, " btn-", (ctx.outlined ? "outline-" : "") + ctx.color, " ", ctx.iconClass, " ", ctx.className, "");
          i0.ɵɵproperty("id", ctx.nsaId)("type", ctx.type)("ngClass", i0.ɵɵpureFunction2(10, _c1, ctx.disabled, ctx.fullWidth));
          i0.ɵɵadvance(3);
          i0.ɵɵconditional(!!ctx.icon ? 4 : -1);
        }
      },
      dependencies: [CommonModule, i1.NgClass],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ButtonComponent, [{
    type: Component,
    args: [{
      selector: 'nsa-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [CommonModule],
      template: "<div (click)=\"onClick($event)\">\r\n  <button\r\n    [id]=\"nsaId\"\r\n    [type]=\"type\"\r\n    class=\"btn btn-nsa btn-{{size}} btn-{{(outlined ? 'outline-' : '') + color}} {{iconClass}} {{className}}\"\r\n    [ngClass]=\"{ 'disabled': disabled, 'w-100': fullWidth }\">\r\n    <span>\r\n      <ng-content></ng-content>\r\n    </span>\r\n    @if(!!icon) { <i class=\"bi bi-{{icon}}\"></i> }\r\n  </button>\r\n</div>\r\n"
    }]
  }], null, {
    nsaId: [{
      type: Input,
      args: [{
        required: true
      }]
    }],
    disabled: [{
      type: Input
    }],
    type: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    color: [{
      type: Input
    }],
    outlined: [{
      type: Input
    }],
    className: [{
      type: Input
    }],
    fullWidth: [{
      type: Input
    }],
    icon: [{
      type: Input
    }],
    iconColor: [{
      type: Input
    }]
  });
})();
class ButtonIconComponent {
  constructor() {
    this.type = 'button';
    this.size = 'sm';
    this.color = 'primary';
    this.outlined = false;
    this.className = '';
  }
  onClick(event) {
    this.disabled && event.stopImmediatePropagation();
    this.disabled && event.stopPropagation();
  }
  static {
    this.ɵfac = function ButtonIconComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ButtonIconComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ButtonIconComponent,
      selectors: [["nsa-button-icon"]],
      inputs: {
        nsaId: "nsaId",
        icon: "icon",
        disabled: "disabled",
        type: "type",
        size: "size",
        color: "color",
        outlined: "outlined",
        className: "className"
      },
      decls: 3,
      vars: 13,
      consts: [[3, "click"], [3, "id", "type", "ngClass"]],
      template: function ButtonIconComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵlistener("click", function ButtonIconComponent_Template_div_click_0_listener($event) {
            return ctx.onClick($event);
          });
          i0.ɵɵelementStart(1, "button", 1);
          i0.ɵɵelement(2, "i");
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵclassMapInterpolate3("btn btn-nsa btn-nsa-icon btn-", ctx.size, " btn-", (ctx.outlined ? "outline-" : "") + ctx.color, " ", ctx.className, "");
          i0.ɵɵproperty("id", ctx.nsaId)("type", ctx.type)("ngClass", i0.ɵɵpureFunction1(11, _c2, ctx.disabled));
          i0.ɵɵadvance();
          i0.ɵɵclassMapInterpolate1("bi bi-", ctx.icon, "");
        }
      },
      dependencies: [CommonModule, i1.NgClass],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ButtonIconComponent, [{
    type: Component,
    args: [{
      selector: 'nsa-button-icon',
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [CommonModule],
      template: "<div (click)=\"onClick($event)\">\r\n  <button\r\n    [id]=\"nsaId\"\r\n    [type]=\"type\"\r\n    class=\"btn btn-nsa btn-nsa-icon btn-{{size}} btn-{{(outlined ? 'outline-' : '') + color}} {{className}}\"\r\n    [ngClass]=\"{ 'disabled': disabled }\">\r\n    <i class=\"bi bi-{{icon}}\"></i>\r\n  </button>\r\n</div>\r\n"
    }]
  }], null, {
    nsaId: [{
      type: Input,
      args: [{
        required: true
      }]
    }],
    icon: [{
      type: Input,
      args: [{
        required: true
      }]
    }],
    disabled: [{
      type: Input
    }],
    type: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    color: [{
      type: Input
    }],
    outlined: [{
      type: Input
    }],
    className: [{
      type: Input
    }]
  });
})();
class ButtonModule {
  static {
    this.ɵfac = function ButtonModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ButtonModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ButtonModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [ButtonComponent, ButtonIconComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ButtonModule, [{
    type: NgModule,
    args: [{
      imports: [ButtonComponent, ButtonIconComponent],
      exports: [ButtonComponent, ButtonIconComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ButtonComponent, ButtonIconComponent, ButtonModule };
