import * as i0 from '@angular/core';
import { Directive, Input, EventEmitter, Component, ChangeDetectionStrategy, Optional, SkipSelf, Inject, Output, NgModule } from '@angular/core';
import { CollapseDirective } from '@nesea/ngx-ui-kit/shared';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@jsverse/transloco';
import { TranslocoModule } from '@jsverse/transloco';
import { TranslocoLocaleModule } from '@jsverse/transloco-locale';
const _c0 = ["*"];
const _c1 = a0 => ({
  "nsa-accordion-status": a0
});
const _c2 = (a0, a1) => ({
  "collapsed": a0,
  "hide-toggle": a1
});
function AccordionComponent_Conditional_4_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "transloco");
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind2(1, 1, ctx_r0.label, ctx_r0.translationParams), " ");
  }
}
function AccordionComponent_Conditional_4_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵtextInterpolate1(" ", ctx_r0.translatedLabel, " ");
  }
}
function AccordionComponent_Conditional_4_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "transloco");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵclassMapInterpolate1("badge nsa-accordion-status-badge text-bg-", ctx_r0.statusColor, "");
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind2(2, 4, ctx_r0.statusLabel, ctx_r0.translationParams), " ");
  }
}
function AccordionComponent_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 6);
    i0.ɵɵtemplate(1, AccordionComponent_Conditional_4_Conditional_1_Template, 2, 4)(2, AccordionComponent_Conditional_4_Conditional_2_Template, 1, 1);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(3, AccordionComponent_Conditional_4_Conditional_3_Template, 3, 7, "span", 7);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵconditional(!!ctx_r0.label ? 1 : 2);
    i0.ɵɵadvance(2);
    i0.ɵɵconditional(!!ctx_r0.statusLabel && !!ctx_r0.statusColor ? 3 : -1);
  }
}
function AccordionComponent_Conditional_5_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "transloco");
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind2(1, 1, ctx_r0.label, ctx_r0.translationParams), " ");
  }
}
function AccordionComponent_Conditional_5_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵtextInterpolate1(" ", ctx_r0.translatedLabel, " ");
  }
}
function AccordionComponent_Conditional_5_Conditional_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "transloco");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵclassMapInterpolate1("badge nsa-accordion-status-badge text-bg-", ctx_r0.statusColor, "");
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind2(2, 4, ctx_r0.statusLabel, ctx_r0.translationParams), " ");
  }
}
function AccordionComponent_Conditional_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 8)(1, "span", 6);
    i0.ɵɵtemplate(2, AccordionComponent_Conditional_5_Conditional_2_Template, 2, 4)(3, AccordionComponent_Conditional_5_Conditional_3_Template, 1, 1);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "span", 9);
    i0.ɵɵtext(5);
    i0.ɵɵpipe(6, "transloco");
    i0.ɵɵelementEnd()();
    i0.ɵɵtemplate(7, AccordionComponent_Conditional_5_Conditional_7_Template, 3, 7, "span", 7);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵconditional(!!ctx_r0.label ? 2 : 3);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(6, 3, ctx_r0.subLabel, ctx_r0.translationParams));
    i0.ɵɵadvance(2);
    i0.ɵɵconditional(!!ctx_r0.statusLabel && !!ctx_r0.statusColor ? 7 : -1);
  }
}
class AccordionManagerDirective {
  constructor() {
    this.multi = false;
    this._accordionList = [];
  }
  add(accordion) {
    this._accordionList.push(accordion);
  }
  remove(accordion) {
    this._accordionList = this._accordionList.filter(({
      nsaId: id
    }) => id !== accordion.nsaId);
  }
  handleAccordions(accordionId) {
    if (!this.multi) {
      this._accordionList.filter(({
        nsaId: id,
        isOpen
      }) => id !== accordionId && isOpen).forEach(accordion => {
        accordion.closeAccordion();
        accordion.closed.emit();
        accordion.expanded.emit(accordion.isOpen);
      });
    }
  }
  static {
    this.ɵfac = function AccordionManagerDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AccordionManagerDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: AccordionManagerDirective,
      selectors: [["", "nsaAccordionManager", ""]],
      inputs: {
        multi: "multi"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AccordionManagerDirective, [{
    type: Directive,
    args: [{
      selector: '[nsaAccordionManager]'
    }]
  }], () => [], {
    multi: [{
      type: Input
    }]
  });
})();
class AccordionComponent {
  constructor(_cdr, manager) {
    this._cdr = _cdr;
    this.manager = manager;
    this.translationParams = {};
    this.className = '';
    this.isOpen = false;
    this.hideToggle = false;
    this.opened = new EventEmitter();
    this.closed = new EventEmitter();
    this.expanded = new EventEmitter();
  }
  ngOnInit() {
    this.manager?.add(this);
  }
  ngOnDestroy() {
    this.manager?.remove(this);
  }
  ngOnChanges(changes) {
    if ('isOpen' in changes && !changes['isOpen'].firstChange) {
      this.onButtonClick(false);
    }
  }
  onButtonClick(handleToggle = true) {
    if (handleToggle) {
      this._toggle();
    }
    this.isOpen ? this.opened.emit() : this.closed.emit();
    this.expanded.emit(this.isOpen);
    this.manager?.handleAccordions(this.nsaId);
  }
  closeAccordion() {
    this.isOpen = false;
    this._cdr.markForCheck();
  }
  _toggle() {
    this.isOpen = !this.isOpen;
  }
  static {
    this.ɵfac = function AccordionComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AccordionComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(AccordionManagerDirective, 12));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AccordionComponent,
      selectors: [["nsa-accordion"]],
      inputs: {
        nsaId: "nsaId",
        label: "label",
        subLabel: "subLabel",
        statusLabel: "statusLabel",
        translatedLabel: "translatedLabel",
        statusColor: "statusColor",
        translationParams: "translationParams",
        className: "className",
        isOpen: "isOpen",
        hideToggle: "hideToggle"
      },
      outputs: {
        opened: "opened",
        closed: "closed",
        expanded: "expanded"
      },
      features: [i0.ɵɵNgOnChangesFeature],
      ngContentSelectors: _c0,
      decls: 9,
      vars: 13,
      consts: [[3, "id", "ngClass"], [1, "accordion-item"], [1, "accordion-header"], ["role", "button", 1, "accordion-button", "p-4", 3, "click", "ngClass"], [1, "accordion-collapse", "collapse", 3, "nsaCollapse"], [1, "accordion-body", "p-4", "pt-2"], [1, "accordion-title"], [3, "class"], [1, "d-flex", "flex-column"], [1, "accordion-subtitle"]],
      template: function AccordionComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "h2", 2)(3, "a", 3);
          i0.ɵɵlistener("click", function AccordionComponent_Template_a_click_3_listener() {
            return ctx.onButtonClick();
          });
          i0.ɵɵtemplate(4, AccordionComponent_Conditional_4_Template, 4, 2)(5, AccordionComponent_Conditional_5_Template, 8, 6);
          i0.ɵɵelementEnd()();
          i0.ɵɵelementStart(6, "div", 4)(7, "div", 5);
          i0.ɵɵprojection(8);
          i0.ɵɵelementEnd()()()();
        }
        if (rf & 2) {
          i0.ɵɵclassMapInterpolate1("accordion ", ctx.className, "");
          i0.ɵɵproperty("id", ctx.nsaId)("ngClass", i0.ɵɵpureFunction1(8, _c1, !!ctx.statusLabel && !!ctx.statusColor));
          i0.ɵɵadvance(3);
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(10, _c2, !ctx.isOpen, ctx.hideToggle));
          i0.ɵɵadvance();
          i0.ɵɵconditional(!ctx.subLabel ? 4 : 5);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("nsaCollapse", !ctx.isOpen);
        }
      },
      dependencies: [CommonModule, i1.NgClass, TranslocoModule, i2.TranslocoPipe, TranslocoLocaleModule, CollapseDirective],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AccordionComponent, [{
    type: Component,
    args: [{
      selector: 'nsa-accordion',
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [CommonModule, TranslocoModule, TranslocoLocaleModule, CollapseDirective],
      template: "<div\r\n  [id]=\"nsaId\"\r\n  class=\"accordion {{className}}\"\r\n  [ngClass]=\"{ 'nsa-accordion-status': !!statusLabel && !!statusColor }\">\r\n  <div class=\"accordion-item\">\r\n    <h2 class=\"accordion-header\">\r\n      <a\r\n        class=\"accordion-button p-4\"\r\n        [ngClass]=\"{ 'collapsed': !isOpen, 'hide-toggle': hideToggle }\"\r\n        role=\"button\"\r\n        (click)=\"onButtonClick()\">\r\n        @if(!subLabel) {\r\n          <span class=\"accordion-title\">\r\n            @if(!!label) {\r\n              {{ label | transloco: translationParams }}\r\n            } @else {\r\n              {{ translatedLabel }}\r\n            }\r\n          </span>\r\n\r\n          @if(!!statusLabel && !!statusColor) {\r\n            <span class=\"badge nsa-accordion-status-badge text-bg-{{statusColor}}\">\r\n              {{ statusLabel | transloco: translationParams }}\r\n            </span>\r\n          }\r\n        } @else {\r\n          <span class=\"d-flex flex-column\">\r\n            <span class=\"accordion-title\">\r\n              @if(!!label) {\r\n                {{ label | transloco: translationParams }}\r\n              } @else {\r\n                {{ translatedLabel }}\r\n              }\r\n            </span>\r\n            <span class=\"accordion-subtitle\">{{ subLabel | transloco: translationParams }}</span>\r\n          </span>\r\n\r\n          @if(!!statusLabel && !!statusColor) {\r\n            <span class=\"badge nsa-accordion-status-badge text-bg-{{statusColor}}\">\r\n              {{ statusLabel | transloco: translationParams }}\r\n            </span>\r\n          }\r\n        }\r\n      </a>\r\n    </h2>\r\n\r\n    <div class=\"accordion-collapse collapse\" [nsaCollapse]=\"!isOpen\">\r\n      <div class=\"accordion-body p-4 pt-2\">\r\n\r\n          <ng-content></ng-content>\r\n\r\n      </div>\r\n    </div>\r\n  </div>\r\n</div>\r\n"
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: AccordionManagerDirective,
    decorators: [{
      type: Optional
    }, {
      type: SkipSelf
    }, {
      type: Inject,
      args: [AccordionManagerDirective]
    }]
  }], {
    nsaId: [{
      type: Input,
      args: [{
        required: true
      }]
    }],
    label: [{
      type: Input
    }],
    subLabel: [{
      type: Input
    }],
    statusLabel: [{
      type: Input
    }],
    translatedLabel: [{
      type: Input
    }],
    statusColor: [{
      type: Input
    }],
    translationParams: [{
      type: Input
    }],
    className: [{
      type: Input
    }],
    isOpen: [{
      type: Input
    }],
    hideToggle: [{
      type: Input
    }],
    opened: [{
      type: Output
    }],
    closed: [{
      type: Output
    }],
    expanded: [{
      type: Output
    }]
  });
})();
class AccordionModule {
  static {
    this.ɵfac = function AccordionModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AccordionModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: AccordionModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [AccordionComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AccordionModule, [{
    type: NgModule,
    args: [{
      imports: [AccordionComponent, AccordionManagerDirective],
      exports: [AccordionComponent, AccordionManagerDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AccordionComponent, AccordionManagerDirective, AccordionModule };
