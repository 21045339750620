import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '@jsverse/transloco';
import { EnumUtils } from '@shared/utils/enum.utils';
import { StrategicProgressEnum } from '@shared/enums/strategic-progress.enum';
import { ITypologyIT } from '@shared/models/interfaces/typology.interface';

@Pipe({
  name: 'strategicProgress',
  standalone: true
})
export class StrategicProgressPipe implements PipeTransform {

  transform(value: StrategicProgressEnum, options?: ITypologyIT<StrategicProgressEnum>[]): string {
    if(!value) return '-';
    const option: ITypologyIT<StrategicProgressEnum> = options?.find(({ codice }) => codice === value);
    if(!!option) {
      return option.nome;
    } else {
      const key: string = EnumUtils.getKey(StrategicProgressEnum, value);
      return !!value ? translate(`STRATEGIC_PROCESS.PROGRESS.TYPE.OPTIONS.${key}`) : '';
    }
  }

}
