import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractModal, ModalViewerService } from '@nesea/ngx-ui-kit/modal';
import { IModalInput, IModalOutput, ITranslationParams } from '@nesea/ngx-ui-kit/shared';

export interface IConfirmModalInput extends IModalInput {
  message?: string;
  showNote?: boolean;
  translateParams?: ITranslationParams;
}
export interface IConfirmModalOutput extends IModalOutput {
  outcome: boolean,
  note?: string
}

// TODO: Move in modals directory
@Component({
    selector: 'nsf-confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrl: './confirm-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ConfirmModalComponent extends AbstractModal<IConfirmModalInput, IConfirmModalOutput> {

  note: string;

  constructor(
    protected override modalViewerService: ModalViewerService
  ) {
    super(modalViewerService);
  }

  override onInit(): void {}
  override onDestroy(): void {}

  onAbort(): void {
    this.close({ outcome: false });
  }

  onConfirm(): void {
    this.close({ outcome: true, note: this.data.showNote ? this.note : null });
  }

}
