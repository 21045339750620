import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnInit } from '@angular/core';
import { ITableColumn } from '@nesea/ngx-ui-kit/shared';
import { IOrder } from '@shared/models/interfaces/order.interface';

@Component({
  selector: 'nsf-timesheet-summary-activations',
  templateUrl: './timesheet-summary-activations.component.html',
  styleUrl: './timesheet-summary-activations.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class TimesheetSummaryActivationsComponent implements OnInit, AfterViewInit {

  @Input({ required: true }) management: boolean;
  @Input({ required: true }) orders: IOrder[];

  columns: ITableColumn<IOrder>[] = [];
  items: IOrder[] = [];

  private _cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  ngOnInit(): void {
    this.items = [...this.orders]
      .filter(item => !this.management ? !!item.flagConsuntivazione : true)
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  ngAfterViewInit(): void {
    let columns: ITableColumn<IOrder>[] = [
      {
        id: 'name',
        label: 'LABEL.ORDER.TEXT',
        property: 'name'
      },
      {
        id: 'customer',
        label: 'LABEL.CUSTOMER.TEXT',
        property: 'clientName'
      },
      {
        id: 'type',
        label: 'LABEL.TYPE.TEXT',
        property: 'tipologiaCommessa',
        getValue: (item): string => item.tipologiaCommessa?.descrizione
      }
    ];
    if(!!this.management) {
      columns = [
        ...columns,
        {
          id: 'accounting',
          label: 'ACTIVATIONS_MANAGEMENT.ACCOUNTING.LABEL',
          property: 'flagConsuntivazione',
          getStatus: ({ flagConsuntivazione }) => flagConsuntivazione ? 'success' : 'danger'
        }
      ];
    }
    this.columns = [...columns];

    this._cdr.detectChanges();
  }

}
