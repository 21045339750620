import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractModal, ModalViewerService } from '@nesea/ngx-ui-kit/modal';
import { IDatepickerConfig, IModalInput, IModalOutput } from '@nesea/ngx-ui-kit/shared';
import { DateUtils } from '@shared/utils/date.utils';

export interface ITimesheetSearchModalInput extends IModalInput {
  date: Date;
}
export interface ITimesheetSearchModalOutput extends IModalOutput {
  outcome: boolean;
  date?: Date;
}

@Component({
    selector: 'nsf-timesheet-search-modal',
    templateUrl: './timesheet-search-modal.component.html',
    styleUrl: './timesheet-search-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TimesheetSearchModalComponent extends AbstractModal<ITimesheetSearchModalInput, ITimesheetSearchModalOutput> {

  date: Date;

  dateConfig: IDatepickerConfig = {
    adaptivePosition: true,
    view: 'month',
    maxDate: DateUtils.lastDayOfCurrentMonth()
  };

  constructor(
    protected override modalViewerService: ModalViewerService
  ) {
    super(modalViewerService);
  }

  override onInit(): void {
    this.date = new Date(this.data.date);
  }
  override onDestroy(): void {}

  onDateChange(date: Date): void {
    this.date = new Date(date);
  }

  onAbort(): void {
    this.close({ outcome: false });
  }

  onConfirm(): void {
    this.close({ outcome: true, date: this.date });
  }

}
