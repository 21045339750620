import * as i0 from '@angular/core';
import { Injectable, Inject, Directive, HostBinding, Input, inject, ElementRef, HostListener, EventEmitter, Output, Pipe, InjectionToken, NgModule } from '@angular/core';
import * as i1 from '@jsverse/transloco-locale';
import { TRANSLOCO_LOCALE_CONFIG, TranslocoLocaleModule } from '@jsverse/transloco-locale';
import * as i1$1 from '@angular/animations';
import { trigger, state, style, AUTO_STYLE, transition, animate } from '@angular/animations';
import { Observable, forkJoin, tap, catchError, throwError, map } from 'rxjs';
import deepEqual from 'deep-equal';
import { v4 } from 'uuid';
import { DOCUMENT, CommonModule, KeyValuePipe } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
const Breakpoints = {
  MIN_WIDTH: {
    SM: 576,
    MD: 768,
    LG: 992,
    XL: 1200,
    XXL: 1400
  },
  MAX_WIDTH: {
    XS: 575.98,
    SM: 767.98,
    MD: 991.98,
    LG: 1199.98,
    XL: 1399.98
  }
};
let service;
let localeConfig;
function getDefaultOptions(locale, type, localeConfig) {
  const defaultConfig = localeConfig.global ? localeConfig.global[type] : {};
  const settings = localeConfig.localeBased ? localeConfig.localeBased[locale] : {};
  return settings?.[type] ? {
    ...defaultConfig,
    ...settings[type]
  } : defaultConfig;
}
function translateDate(date, options = {}, locale) {
  locale = locale || service.getLocale();
  return service.localizeDate(date, locale, {
    ...getDefaultOptions(locale, 'date', localeConfig),
    ...options
  });
}
class TranslationDateService {
  constructor(_localeConfig, _service) {
    this._localeConfig = _localeConfig;
    this._service = _service;
    localeConfig = _localeConfig;
    service = _service;
  }
  static {
    this.ɵfac = function TranslationDateService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TranslationDateService)(i0.ɵɵinject(TRANSLOCO_LOCALE_CONFIG), i0.ɵɵinject(i1.TranslocoLocaleService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TranslationDateService,
      factory: TranslationDateService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TranslationDateService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [TRANSLOCO_LOCALE_CONFIG]
    }]
  }, {
    type: i1.TranslocoLocaleService
  }], null);
})();
const DEFAULT_DURATION$1 = 300;
const collapse = trigger('collapse', [state('false', style({
  height: '0',
  visibility: 'hidden',
  overflow: 'hidden'
})), state('true', style({
  height: AUTO_STYLE,
  visibility: AUTO_STYLE
})), transition('false => true', animate(`${DEFAULT_DURATION$1}ms ease-in-out`)), transition('true => false', animate(`${DEFAULT_DURATION$1}ms ease-out`))]);
const hide = trigger('hide', [state('false', style({
  visibility: 'hidden',
  transform: AUTO_STYLE
})), state('true', style({
  visibility: 'visible',
  transform: 'none'
})), transition('false => true', animate(`${DEFAULT_DURATION$1}ms ease-in-out`)), transition('true => false', animate(`${DEFAULT_DURATION$1}ms ease-out`))]);
const slideInOut = trigger('slideInOut', [state('false', style({
  visibility: 'hidden',
  opacity: 0
}), {
  params: {
    sign: '+'
  }
}), state('true', style({
  visibility: AUTO_STYLE,
  opacity: 1
})), transition('false => true', [style({
  transform: 'translateY({{sign}}10px)',
  opacity: 0
}), animate('.12s ease-in')]), transition('true => false', animate('.1s ease-out', style({
  transform: 'translateY({{sign}}5px)',
  opacity: 0
})))]);
const fadeIn = trigger('fadeIn', [state('in', style({
  opacity: 1
})), transition(':enter', [style({
  opacity: 0
}), animate(300)]), transition(':leave', animate(200, style({
  opacity: 0
})))]);
const AnimationUtils = {
  collapse,
  hide,
  slideInOut,
  fadeIn
};

/** Returns `true` if the given dates are equal */
const isEqual = (firstDate, secondDate) => new Date(firstDate)?.toString() === new Date(secondDate)?.toString();
/** Return the start of a day for the given date */
const startOfDay = date => !!date ? new Date(new Date(date).setHours(0, 0, 0)) : null;
/** Subtract the specified number of months from the given date */
const subMonths = (date, months) => {
  const newDate = new Date(date);
  return !!newDate ? new Date(date.setMonth(date.getMonth() - months)) : null;
};
const currentDateByHours = (hours, minutes, seconds) => {
  let date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(seconds);
  return date;
};
const TODAY = startOfDay(new Date());
const DateUtils = {
  TODAY,
  isEqual,
  startOfDay,
  subMonths,
  currentDateByHours
};
const getMimeTypeFromBase64 = base64 => {
  const map = new Map([['/9j/', 'image/jpeg'], ['iVBORw', 'image/png'], ['JVBERi0', 'application/pdf']]);
  let output;
  for (const [prefix, mimeType] of map) {
    if (base64.startsWith(prefix)) {
      output = mimeType;
      break;
    }
  }
  return output;
};
const getMimeTypeFromFileExtension = extension => {
  const map = new Map([['.pdf', 'application/pdf'], ['.png', 'image/png'], ['.jpg', 'image/jpeg'], ['.jpeg', 'image/jpeg']]);
  return map.get(extension);
};
const getFileName = contentDisposition => {
  if (!contentDisposition) return null;else return decodeURI(contentDisposition?.match(/(?<=filename(?:=|\*=(?:[\w\-]+'')))["']?(?<filename>[^"';\n]+)["']?/g)?.pop()?.replace(/\"/g, ''));
};
const readFileB64 = (file, includeMimeType = true) => new Observable(subscriber => {
  if (!file) {
    subscriber.next('');
    subscriber.complete();
    return;
  }
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    const result = reader.result;
    !includeMimeType ? subscriber.next(result.substring(result.indexOf(',') + 1)) : subscriber.next(result);
    subscriber.complete();
  };
  reader.onerror = error => subscriber.error(error);
});
const readFileByteArray = (file, origin = false) => new Observable(subscriber => {
  if (!file) {
    subscriber.next(null);
    subscriber.complete();
    return;
  }
  const reader = new FileReader();
  reader.readAsArrayBuffer(file);
  reader.onload = () => {
    const arrayBuffer = reader.result;
    const result = new Uint8Array(arrayBuffer);
    if (!!origin) {
      subscriber.next({
        file,
        byteArray: result
      });
    } else {
      subscriber.next(result);
    }
    subscriber.complete();
  };
  reader.onerror = error => subscriber.error(error);
});
const readFilesByteArray = (files, origin = false) => new Observable(subscriber => {
  forkJoin(files.map(file => readFileByteArray(file, origin))).pipe(tap(res => {
    subscriber.next(res);
    subscriber.complete();
  }), catchError(err => {
    subscriber.error(err);
    return throwError(() => err);
  })).subscribe();
});
const readFile = (file, origin = false) => new Observable(subscriber => {
  if (!file) {
    subscriber.next('');
    subscriber.complete();
    return;
  }
  const reader = new FileReader();
  reader.readAsText(file);
  reader.onload = () => {
    const result = reader.result;
    if (!!origin) {
      subscriber.next({
        file,
        text: result
      });
    } else {
      subscriber.next(result);
    }
    subscriber.complete();
  };
  reader.onerror = error => subscriber.error(error);
});
const readFiles = (files, origin = false) => new Observable(subscriber => {
  forkJoin(files.map(file => readFile(file, origin))).pipe(tap(res => {
    subscriber.next(res);
    subscriber.complete();
  }), catchError(err => {
    subscriber.error(err);
    return throwError(() => err);
  })).subscribe();
});
const addMimeTypeToBlob = file => {
  return readFileB64(file, false).pipe(map(base64 => getMimeTypeFromBase64(base64)), map(mimeType => new Blob([file], {
    type: mimeType
  })));
};
const getMaxFileSize = size => `${size / 1024} MB`;
const FileUtils = {
  getMimeTypeFromBase64,
  getMimeTypeFromFileExtension,
  getFileName,
  readFileB64,
  readFileByteArray,
  readFilesByteArray,
  readFile,
  readFiles,
  addMimeTypeToBlob,
  getMaxFileSize
};
const isObject = value => !!value && !Array.isArray(value) && typeof value === 'object';
const getProperty = (obj, path) => !!path ? path.split('.').reduce((o, p) => o && o[p], obj) : obj;
const equal = (firstObj, secondObj) => deepEqual(firstObj, secondObj);
const ObjectUtils = {
  isObject,
  getProperty,
  equal
};
const generateId = (context, id) => {
  return !!id || id === 0 ? `nsa-${context}-${id}` : `nsa-${context}-${v4()}`;
};
const hasValue = value => {
  return typeof value === 'undefined' || typeof value === 'object' || typeof value === 'string' ? !!value : true;
};
const SharedUtils = {
  generateId,
  hasValue
};
const getTransitionDurationFromElement = element => {
  if (!element) {
    return 0;
  }
  // Get transition-duration of the element
  let {
    transitionDuration,
    transitionDelay
  } = window.getComputedStyle(element);
  const floatTransitionDuration = Number.parseFloat(transitionDuration);
  const floatTransitionDelay = Number.parseFloat(transitionDelay);
  // Return 0 if element or transition duration is not found
  if (!floatTransitionDuration && !floatTransitionDelay) {
    return 0;
  }
  // If multiple durations are defined, take the first
  transitionDuration = transitionDuration.split(',')[0];
  transitionDelay = transitionDelay.split(',')[0];
  return (Number.parseFloat(transitionDuration) + Number.parseFloat(transitionDelay)) * 1000;
};
const TransitionUtils = {
  getTransitionDurationFromElement
};
function timepickerMinValidator(min) {
  return control => {
    if (!control.value) return null;else {
      const value = control.value;
      const valueDate = DateUtils.currentDateByHours(value.hours, value.minutes, 0);
      const minDate = DateUtils.currentDateByHours(min.hours, min.minutes, 0);
      return valueDate.getTime() < minDate.getTime() ? {
        timepickerMin: {
          requiredTime: `${min.hours.toString().padStart(2, '0')}:${min.minutes.toString().padStart(2, '0')}`
        }
      } : null;
    }
  };
}
function timepickerMaxValidator(max) {
  return control => {
    if (!control.value) return null;else {
      const value = control.value;
      const valueDate = DateUtils.currentDateByHours(value.hours, value.minutes, 0);
      const maxDate = DateUtils.currentDateByHours(max.hours, max.minutes, 0);
      return valueDate.getTime() > maxDate.getTime() ? {
        timepickerMax: {
          requiredTime: `${max.hours.toString().padStart(2, '0')}:${max.minutes.toString().padStart(2, '0')}`
        }
      } : null;
    }
  };
}
function timepickerRangeValidator(ranges) {
  return control => {
    if (!control.value || !ranges.length) return null;else {
      const value = control.value;
      const valueDate = DateUtils.currentDateByHours(value.hours, value.minutes, 0);
      const sortedRanges = [...ranges].sort((x, y) => {
        const minX = DateUtils.currentDateByHours(x.min.hours, x.min.minutes, 0);
        const minY = DateUtils.currentDateByHours(y.min.hours, y.min.minutes, 0);
        if (minX.getTime() > minY.getTime()) {
          return 1;
        } else if (minX.getTime() < minY.getTime()) {
          return -1;
        } else return 0;
      });
      const invalidRanges = sortedRanges.filter(item => {
        const minDate = DateUtils.currentDateByHours(item.min.hours, item.min.minutes, 0);
        const maxDate = DateUtils.currentDateByHours(item.max.hours, item.max.minutes, 0);
        return valueDate.getTime() > maxDate.getTime() || valueDate.getTime() < minDate.getTime();
      });
      if (invalidRanges.length !== sortedRanges.length) {
        return null;
      } else {
        if (invalidRanges.length === 1) {
          return {
            timepickerSingleRange: {
              minTime: `${invalidRanges[0].min.hours.toString().padStart(2, '0')}:${invalidRanges[0].min.minutes.toString().padStart(2, '0')}`,
              maxTime: `${invalidRanges[0].max.hours.toString().padStart(2, '0')}:${invalidRanges[0].max.minutes.toString().padStart(2, '0')}`
            }
          };
        } else {
          const timeSlots = invalidRanges.map(range => {
            const minTime = `${range.min.hours.toString().padStart(2, '0')}:${range.min.minutes.toString().padStart(2, '0')}`;
            const maxTime = `${range.max.hours.toString().padStart(2, '0')}:${range.max.minutes.toString().padStart(2, '0')}`;
            return `${minTime} - ${maxTime}`;
          }).join(', ');
          return {
            timepickerMultipleRange: {
              timeSlots
            }
          };
        }
      }
    }
  };
}
function timepickerStepValidator(control) {
  if (!control.value) return null;else {
    const minutes = control.value.minutes;
    return minutes % 30 !== 0 ? {
      timepickerStep: true
    } : null;
  }
}
function timeGreaterThanValidator(targetControl) {
  return control => {
    if (!control.value || !targetControl.value) return null;else {
      const targetValue = targetControl.getRawValue();
      const controlValue = control.getRawValue();
      return controlValue.hours * 60 + controlValue.minutes <= targetValue.hours * 60 + targetValue.minutes ? {
        timepickerMin: {
          requiredTime: `${targetValue.hours.toString().padStart(2, '0')}:${targetValue.minutes.toString().padStart(2, '0')}`
        }
      } : null;
    }
  };
}
function fileSizeValidator(maxSize, multiple) {
  return control => {
    if (!control.value) return null;else {
      if (multiple) {
        const files = control.value;
        if (!!files?.length && !files.every(file => file.size > maxSize * 1024)) return {
          fileSize: {
            maxSize: FileUtils.getMaxFileSize(maxSize)
          }
        };else return null;
      } else {
        const {
          size
        } = control.value;
        if (size > maxSize * 1024) return {
          fileSize: {
            maxSize: FileUtils.getMaxFileSize(maxSize)
          }
        };else return null;
      }
    }
  };
}
function fileExtensionValidator(acceptedFileTypes, multiple) {
  return control => {
    if (!control.value) return null;else {
      const mimeTypes = [];
      acceptedFileTypes.forEach(fileType => mimeTypes.push(FileUtils.getMimeTypeFromFileExtension(fileType)));
      if (multiple) {
        const files = control.value;
        if (!!files?.length && !files.every(file => mimeTypes.includes(file.type))) return {
          fileExtension: {
            acceptedExtensions: acceptedFileTypes.map(type => type.replace(/\./g, "").toLocaleUpperCase()).join(', ')
          }
        };else return null;
      } else {
        const {
          type: mimeType
        } = control.value;
        if (!mimeTypes.includes(mimeType)) return {
          fileExtension: {
            acceptedExtensions: acceptedFileTypes.map(type => type.replace(/\./g, "").toLocaleUpperCase()).join(', ')
          }
        };else return null;
      }
    }
  };
}
class CustomValidators {
  static timepickerMin(min) {
    const minTime = {
      hours: !min || !SharedUtils.hasValue(min.hours) ? 0 : min.hours,
      minutes: !min || !SharedUtils.hasValue(min.minutes) ? 0 : min.minutes
    };
    return timepickerMinValidator(minTime);
  }
  static timepickerMax(max) {
    const maxTime = {
      hours: !max || !SharedUtils.hasValue(max.hours) ? 24 : max.hours,
      minutes: !max || !SharedUtils.hasValue(max.minutes) ? 0 : max.minutes
    };
    return timepickerMaxValidator(maxTime);
  }
  static timepickerRange(ranges) {
    return timepickerRangeValidator(ranges);
  }
  static timepickerStep(control) {
    return timepickerStepValidator(control);
  }
  static timeGreaterThan(targetControl) {
    return timeGreaterThanValidator(targetControl);
  }
  static fileSize(maxSize, multiple) {
    return fileSizeValidator(maxSize, multiple);
  }
  static fileExtension(acceptedFileTypes, multiple) {
    return fileExtensionValidator(acceptedFileTypes, multiple);
  }
}
const DEFAULT_DURATION = 350;
const expandAnimation = [style({
  height: '0',
  visibility: 'hidden'
}), animate(`${DEFAULT_DURATION}ms ease-in-out`, style({
  height: '*',
  visibility: 'visible'
}))];
const collapseAnimation = [style({
  height: '*',
  visibility: 'visible'
}), animate(`${DEFAULT_DURATION}ms ease-out`, style({
  height: 0,
  visibility: 'hidden'
}))];
class CollapseDirective {
  set collapse(value) {
    this.collapseNewValue = value;
    if (!this._player || this._isAnimationDone) {
      this.isExpanded = value;
      this.toggle();
    }
  }
  get collapse() {
    return this.isExpanded;
  }
  constructor(_el, _renderer, _builder) {
    this._el = _el;
    this._renderer = _renderer;
    this.isExpanded = true;
    this.collapseNewValue = true;
    this.isCollapsed = false;
    this.isCollapse = true;
    this.isCollapsing = false;
    this._display = 'block';
    this._stylesLoaded = false;
    this._COLLAPSE_ACTION_NAME = 'collapse';
    this._EXPAND_ACTION_NAME = 'expand';
    this._factoryCollapseAnimation = _builder.build(collapseAnimation);
    this._factoryExpandAnimation = _builder.build(expandAnimation);
  }
  ngAfterViewChecked() {
    this._stylesLoaded = true;
    if (!this._player || !this._isAnimationDone) {
      return;
    }
    this._player.reset();
    this._renderer.setStyle(this._el.nativeElement, 'height', '*');
  }
  /** allows to manually toggle content visibility */
  toggle() {
    if (this.isExpanded) {
      this.hide();
    } else {
      this.show();
    }
  }
  /** allows to manually hide content */
  hide() {
    this.isCollapsing = true;
    this.isExpanded = false;
    this.isCollapsed = true;
    this.isCollapsing = false;
    this._isAnimationDone = false;
    this.animationRun(this._COLLAPSE_ACTION_NAME)(() => {
      this._isAnimationDone = true;
      if (this.collapseNewValue !== this.isCollapsed) {
        this.show();
        return;
      }
      this._renderer.setStyle(this._el.nativeElement, 'display', 'none');
    });
  }
  /** allows to manually show collapsed content */
  show() {
    this._renderer.setStyle(this._el.nativeElement, 'display', this._display);
    this.isCollapsing = true;
    this.isExpanded = true;
    this.isCollapsed = false;
    this.isCollapsing = false;
    this._isAnimationDone = false;
    this.animationRun(this._EXPAND_ACTION_NAME)(() => {
      this._isAnimationDone = true;
      if (this.collapseNewValue !== this.isCollapsed) {
        this.hide();
        return;
      }
      this._renderer.removeStyle(this._el.nativeElement, 'overflow');
    });
  }
  animationRun(action) {
    if (!this._stylesLoaded) {
      return callback => callback();
    }
    this._renderer.setStyle(this._el.nativeElement, 'overflow', 'hidden');
    this._renderer.addClass(this._el.nativeElement, 'collapse');
    const factoryAnimation = action === this._EXPAND_ACTION_NAME ? this._factoryExpandAnimation : this._factoryCollapseAnimation;
    if (this._player) {
      this._player.reset();
    }
    this._player = factoryAnimation.create(this._el.nativeElement);
    this._player.play();
    return callback => this._player?.onDone(callback);
  }
  static {
    this.ɵfac = function CollapseDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CollapseDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i1$1.AnimationBuilder));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: CollapseDirective,
      selectors: [["", "nsaCollapse", ""]],
      hostVars: 7,
      hostBindings: function CollapseDirective_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("aria-hidden", ctx.isCollapsed);
          i0.ɵɵclassProp("collapse", ctx.isCollapse)("show", ctx.collapseNewValue)("collapsing", ctx.isCollapsing);
        }
      },
      inputs: {
        collapse: [0, "nsaCollapse", "collapse"]
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CollapseDirective, [{
    type: Directive,
    args: [{
      selector: '[nsaCollapse]',
      host: {
        '[class.collapse]': 'true'
      }
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.Renderer2
  }, {
    type: i1$1.AnimationBuilder
  }], {
    isExpanded: [{
      type: HostBinding,
      args: ['class.show']
    }],
    collapseNewValue: [{
      type: HostBinding,
      args: ['class.show']
    }],
    isCollapsed: [{
      type: HostBinding,
      args: ['attr.aria-hidden']
    }],
    isCollapse: [{
      type: HostBinding,
      args: ['class.collapse']
    }],
    isCollapsing: [{
      type: HostBinding,
      args: ['class.collapsing']
    }],
    collapse: [{
      type: Input,
      args: ['nsaCollapse']
    }]
  });
})();
class OnlyNumbersDirective {
  constructor() {
    this.allowMultiLine = false;
    this.allowNegative = false;
    this.allowDecimal = false;
    this.maxlength = 0;
    this._el = inject(ElementRef);
  }
  onKeyPress(event) {
    this.validate(event, event.key === 'Enter' ? '\n' : event.key);
  }
  onPaste(event) {
    const pastedText = window.clipboardData && window.clipboardData.getData('Text') // If IE, use window
    || event && event.clipboardData.getData('text/plain'); // Non-IE browsers
    this.validate(event, pastedText);
  }
  onCut(event) {
    this.validate(event, '');
  }
  validate(event, text) {
    const txtInput = this._el.nativeElement;
    const newValue = txtInput.value.substring(0, txtInput.selectionStart) + text + txtInput.value.substring(txtInput.selectionEnd);
    if (!this.regex) {
      this.regex = (0, eval)('/^' + (this.allowNegative ? '-?' : '') + (this.allowDecimal ? '((\\d+\\.?)|(\\.?))\\d*' : '\\d*') + '$/g');
    }
    const lines = this.allowMultiLine ? newValue.split('\n') : [newValue];
    for (const line of lines) {
      const lineText = line.replace('\r', '');
      if (this.maxlength && lineText.length > this.maxlength || !lineText.match(this.regex)) {
        event.preventDefault();
        return;
      }
    }
  }
  static {
    this.ɵfac = function OnlyNumbersDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || OnlyNumbersDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: OnlyNumbersDirective,
      selectors: [["", "nsaOnlyNumbers", ""]],
      hostBindings: function OnlyNumbersDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("keypress", function OnlyNumbersDirective_keypress_HostBindingHandler($event) {
            return ctx.onKeyPress($event);
          })("paste", function OnlyNumbersDirective_paste_HostBindingHandler($event) {
            return ctx.onPaste($event);
          })("cut", function OnlyNumbersDirective_cut_HostBindingHandler($event) {
            return ctx.onCut($event);
          });
        }
      },
      inputs: {
        allowMultiLine: "allowMultiLine",
        allowNegative: "allowNegative",
        allowDecimal: "allowDecimal",
        maxlength: "maxlength"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OnlyNumbersDirective, [{
    type: Directive,
    args: [{
      selector: '[nsaOnlyNumbers]'
    }]
  }], null, {
    allowMultiLine: [{
      type: Input
    }],
    allowNegative: [{
      type: Input
    }],
    allowDecimal: [{
      type: Input
    }],
    maxlength: [{
      type: Input
    }],
    onKeyPress: [{
      type: HostListener,
      args: ['keypress', ['$event']]
    }],
    onPaste: [{
      type: HostListener,
      args: ['paste', ['$event']]
    }],
    onCut: [{
      type: HostListener,
      args: ['cut', ['$event']]
    }]
  });
})();
class OutsideClickDirective {
  constructor() {
    this.emitEvent = true;
    this.includedElements = [];
    this.includeOverlayElements = false;
    this.outsideClick = new EventEmitter();
    this._elementRef = inject(ElementRef);
    this._document = inject(DOCUMENT);
  }
  onDocumentClick(target) {
    if (!this._isMobile()) {
      if (this.emitEvent && !this._contains(target)) {
        this.outsideClick.emit();
      }
    }
  }
  onDocumentTouchStart(target) {
    if (this._isMobile()) {
      if (this.emitEvent && !this._contains(target)) {
        this.outsideClick.emit();
      }
    }
  }
  _contains(target) {
    let elements = [...this.includedElements, this._elementRef.nativeElement];
    if (this.includeOverlayElements) {
      const overlay = this._document.getElementsByClassName('nsa-overlay-container')?.[0];
      if (overlay.contains(target)) elements = [...elements, target];
    }
    return elements.some(el => el.contains(target));
  }
  _isMobile() {
    const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(navigator.userAgent);
  }
  static {
    this.ɵfac = function OutsideClickDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || OutsideClickDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: OutsideClickDirective,
      selectors: [["", "nsaOutsideClick", ""]],
      hostBindings: function OutsideClickDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function OutsideClickDirective_click_HostBindingHandler($event) {
            return ctx.onDocumentClick($event.target);
          }, false, i0.ɵɵresolveDocument)("touchstart", function OutsideClickDirective_touchstart_HostBindingHandler($event) {
            return ctx.onDocumentTouchStart($event.target);
          }, false, i0.ɵɵresolveDocument);
        }
      },
      inputs: {
        emitEvent: [0, "nsaOutsideClick", "emitEvent"],
        includedElements: "includedElements",
        includeOverlayElements: "includeOverlayElements"
      },
      outputs: {
        outsideClick: "outsideClick"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OutsideClickDirective, [{
    type: Directive,
    args: [{
      selector: '[nsaOutsideClick]'
    }]
  }], null, {
    emitEvent: [{
      type: Input,
      args: ['nsaOutsideClick']
    }],
    includedElements: [{
      type: Input
    }],
    includeOverlayElements: [{
      type: Input
    }],
    outsideClick: [{
      type: Output
    }],
    onDocumentClick: [{
      type: HostListener,
      args: ['document:click', ['$event.target']]
    }],
    onDocumentTouchStart: [{
      type: HostListener,
      args: ['document:touchstart', ['$event.target']]
    }]
  });
})();
class PaginatePipe {
  transform(items, params) {
    const {
      pagination,
      serverSide,
      currentPage,
      pageSize
    } = params;
    if (!!pagination && !serverSide) {
      return [...items.slice((currentPage - 1) * pageSize, currentPage * pageSize)];
    } else {
      return [...items];
    }
  }
  static {
    this.ɵfac = function PaginatePipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PaginatePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "nsaPaginate",
      type: PaginatePipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PaginatePipe, [{
    type: Pipe,
    args: [{
      name: 'nsaPaginate'
    }]
  }], null, null);
})();
class TableActionHiddenPipe {
  transform(actions, params) {
    const {
      item
    } = params;
    return [...actions].filter(action => !action.isHidden?.(item));
  }
  static {
    this.ɵfac = function TableActionHiddenPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TableActionHiddenPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "nsaTableActionHidden",
      type: TableActionHiddenPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TableActionHiddenPipe, [{
    type: Pipe,
    args: [{
      name: 'nsaTableActionHidden'
    }]
  }], null, null);
})();
const defaultTranslationPath = '/assets/i18n/';
const defaultTranslationFormErrorPrefix = 'NSA.FORM.ERROR';
const defaultTranslationConfig = {
  defaultLang: 'it',
  availableLangs: ['it'],
  reRenderOnLangChange: true
};
const defaultTranslationLocaleConfig = {
  global: {},
  localeBased: {}
};
const defaultTranslationLocaleLangConfig = {
  'it': 'it-IT'
};
const defaultTranslationLocale = 'it-IT';
const defaultTranslationCurrency = 'EUR';
const defaultTranslationMessageFormatConfig = {
  locales: ['it-IT']
};
const TRANSLATION_FORM_ERROR_PREFIX = new InjectionToken('TRANSLATION_FORM_ERROR_PREFIX', {
  providedIn: 'root',
  factory: () => defaultTranslationFormErrorPrefix
});
class SharedModule {
  static {
    this.ɵfac = function SharedModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SharedModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: SharedModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [KeyValuePipe, PaginatePipe, TableActionHiddenPipe],
      imports: [CommonModule, CommonModule, TranslocoModule, TranslocoLocaleModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SharedModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, OnlyNumbersDirective, PaginatePipe, TableActionHiddenPipe],
      exports: [CommonModule, TranslocoModule, TranslocoLocaleModule, OnlyNumbersDirective, PaginatePipe, TableActionHiddenPipe],
      providers: [KeyValuePipe, PaginatePipe, TableActionHiddenPipe]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AnimationUtils, Breakpoints, CollapseDirective, CustomValidators, DateUtils, FileUtils, ObjectUtils, OnlyNumbersDirective, OutsideClickDirective, PaginatePipe, SharedModule, SharedUtils, TRANSLATION_FORM_ERROR_PREFIX, TableActionHiddenPipe, TransitionUtils, TranslationDateService, translateDate };
