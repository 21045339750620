import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractModal, ModalComponent, ModalViewerService } from '@nesea/ngx-ui-kit/modal';
import { IModalInput, IModalOutput } from '@nesea/ngx-ui-kit/shared';
import { IUser } from '@core/models/interfaces/user.interface';
import { CommonModule } from '@angular/common';
import { CheckboxComponent, InputComponent } from '@nesea/ngx-ui-kit/form';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

export interface IProfileModalInput extends IModalInput {
  user?: IUser;
  timebank?: number;
}
export interface IProfileModalOutput extends IModalOutput { }

@Component({
  selector: 'nsf-profile-modal',
  templateUrl: './profile-modal.component.html',
  styleUrl: './profile-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ModalComponent,
    InputComponent,
    FormsModule,
    TranslocoModule,
    CheckboxComponent,
    ReactiveFormsModule
  ]
})
export class ProfileModalComponent extends AbstractModal<IProfileModalInput, IProfileModalOutput> {

  otherData: boolean = false;

  constructor(
    protected override modalViewerService: ModalViewerService
  ) {
    super(modalViewerService);
  }

  get timebank(): string {
    const { hours, minutes } = this._convertHoursToTime(this.data.timebank);
    return `${hours}h : ${minutes}m`;
  }

  override onInit(): void {}
  override onDestroy(): void {}

  private _convertHoursToTime(dHours: number): { hours: string, minutes: string } {
    const hours = Math.floor(dHours);
    const minutes = Math.round((dHours - hours) * 60);
    return { hours: `${hours}`.padStart(2, '0'), minutes: `${minutes}`.padStart(2, '0') };
  }

}
