import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ITableColumn } from '@nesea/ngx-ui-kit/shared';
import { IOrderTimesheet, IWorkingDayTimesheet } from '@shared/models/interfaces/timesheet.interface';

type OrderTimesheet = { id: number, name: string, customer: string, hours: number };

@Component({
    selector: 'nsf-timesheet-summary-orders',
    templateUrl: './timesheet-summary-orders.component.html',
    styleUrl: './timesheet-summary-orders.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TimesheetSummaryOrdersComponent implements OnInit {

  @Input({ required: true }) workingDays: IWorkingDayTimesheet<IOrderTimesheet>[];

  columns: ITableColumn<OrderTimesheet>[] = [];
  items: OrderTimesheet[] = [];

  ngOnInit(): void {
    this.columns = [
      {
        id: 'name',
        label: 'LABEL.ORDER.TEXT',
        property: 'name'
      },
      {
        id: 'customer',
        label: 'LABEL.CUSTOMER.TEXT',
        property: 'customer'
      },
      {
        id: 'hours',
        label: 'LABEL.TOTAL_HOURS.TEXT',
        property: 'hours',
        getValue: (item): string => {
          const hours: number = Math.floor(item.hours);
          const minutes: number = Math.round((item.hours - hours) * 60);
          return `${hours}h : ${minutes.toString().padStart(2, '0')}m`;
        }
      }
    ];

    this.items = this.workingDays.reduce((output, orders) => {
      orders.commesseOreTimesheet.forEach(order => {
        const id: number = order.id;
        const found: OrderTimesheet = output.find(ord => ord.id === id);
        !!found ? found.hours += order.oreLavorate : output.push({ id, name: order.name, customer: order.clientName, hours: order.oreLavorate });
      });
      return output;
    }, [] as OrderTimesheet[]);
    this.items.sort((a, b) => a.name.localeCompare(b.name));
  }

}
