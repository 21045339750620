import * as i0 from '@angular/core';
import { EventEmitter, inject, Injector, Directive, Input, Output, Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { Validators, NgControl, NgModel, FormControlDirective, FormControlName, ControlContainer, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TRANSLATION_FORM_ERROR_PREFIX, SharedModule } from '@nesea/ngx-ui-kit/shared';
import * as i1 from '@angular/common';
import { KeyValuePipe } from '@angular/common';
import * as i2 from '@jsverse/transloco';
import { translate } from '@jsverse/transloco';

// {
//   providers: [
//     // FormErrorService
//   ]
// }
const _c0 = ["*"];
const _c1 = a0 => ({
  "card card-input": a0
});
const _c2 = a0 => ({
  "is-input-group-invalid": a0
});
const _c3 = a0 => ({
  "text-danger": a0
});
function InputGroupComponent_ng_container_0_Conditional_2_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function InputGroupComponent_ng_container_0_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 3);
    i0.ɵɵtemplate(1, InputGroupComponent_ng_container_0_Conditional_2_ng_container_1_Template, 1, 0, "ng-container", 7);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const labelRef_r1 = i0.ɵɵreference(5);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", labelRef_r1);
  }
}
function InputGroupComponent_ng_container_0_Conditional_3_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function InputGroupComponent_ng_container_0_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, InputGroupComponent_ng_container_0_Conditional_3_ng_container_0_Template, 1, 0, "ng-container", 7);
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const labelRef_r1 = i0.ɵɵreference(5);
    i0.ɵɵproperty("ngTemplateOutlet", labelRef_r1);
  }
}
function InputGroupComponent_ng_container_0_ng_template_4_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 10);
    i0.ɵɵtext(1, " * ");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(1, _c3, ctx_r1.invalid));
  }
}
function InputGroupComponent_ng_container_0_ng_template_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "label", 8);
    i0.ɵɵtext(1);
    i0.ɵɵtemplate(2, InputGroupComponent_ng_container_0_ng_template_4_span_2_Template, 2, 3, "span", 9);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("for", ctx_r1.nsaId);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r1.label, " ");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.required);
  }
}
function InputGroupComponent_ng_container_0_Conditional_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 4);
    i0.ɵɵelement(1, "i");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵpropertyInterpolate1("id", "", ctx_r1.nsaId, "-icon");
    i0.ɵɵadvance();
    i0.ɵɵclassMapInterpolate1("bi ", ctx_r1.icon, "");
  }
}
function InputGroupComponent_ng_container_0_Conditional_9_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 11);
    i0.ɵɵlistener("click", function InputGroupComponent_ng_container_0_Conditional_9_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.clear.emit());
    });
    i0.ɵɵtext(1, " \xD7 ");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const transloco_r4 = i0.ɵɵnextContext().$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("title", !!ctx_r1.clearTitle ? transloco_r4(ctx_r1.clearTitle) : transloco_r4("NSA.ACTION.CLEAR"));
  }
}
function InputGroupComponent_ng_container_0_Conditional_10_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 6);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(2, _c3, ctx_r1.invalid))("innerHtml", ctx_r1.subtext, i0.ɵɵsanitizeHtml);
  }
}
function InputGroupComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 2);
    i0.ɵɵtemplate(2, InputGroupComponent_ng_container_0_Conditional_2_Template, 2, 1, "div", 3)(3, InputGroupComponent_ng_container_0_Conditional_3_Template, 1, 1, "ng-container")(4, InputGroupComponent_ng_container_0_ng_template_4_Template, 3, 3, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementStart(6, "div", 2);
    i0.ɵɵtemplate(7, InputGroupComponent_ng_container_0_Conditional_7_Template, 2, 5, "span", 4);
    i0.ɵɵprojection(8);
    i0.ɵɵtemplate(9, InputGroupComponent_ng_container_0_Conditional_9_Template, 2, 1, "button", 5);
    i0.ɵɵelementEnd()();
    i0.ɵɵtemplate(10, InputGroupComponent_ng_container_0_Conditional_10_Template, 1, 4, "div", 6);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵclassMap(ctx_r1.className);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(12, _c1, !!ctx_r1.cardView));
    i0.ɵɵadvance();
    i0.ɵɵconditional(!!ctx_r1.cardView ? 2 : !!ctx_r1.label ? 3 : -1);
    i0.ɵɵadvance(4);
    i0.ɵɵclassMapInterpolate1("input-group input-group-", ctx_r1.size, " has-validation");
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(14, _c2, ctx_r1.invalid));
    i0.ɵɵadvance();
    i0.ɵɵconditional(!!ctx_r1.icon ? 7 : -1);
    i0.ɵɵadvance(2);
    i0.ɵɵconditional(!!ctx_r1.clearable && !!ctx_r1.hasValue && !ctx_r1.disabled ? 9 : -1);
    i0.ɵɵadvance();
    i0.ɵɵconditional(ctx_r1.invalid || !!ctx_r1.translatedHint ? 10 : -1);
  }
}
class Form {
  constructor() {
    this.size = 'md';
    this.className = '';
    this.label = '';
    this.icon = '';
    this.placeholder = '';
    this.hint = '';
    this.clearable = true;
    this.cardView = false;
    this.translateParams = {};
    this.valueChange = new EventEmitter();
    this.clear = new EventEmitter();
    this._injector = inject(Injector);
    this._formErrorPrefix = inject(TRANSLATION_FORM_ERROR_PREFIX);
    this._keyValuePipe = inject(KeyValuePipe);
  }
  get required() {
    return (this.control.hasValidator(Validators.required) || this.control.hasValidator(Validators.requiredTrue)) && this.control.enabled;
  }
  get invalid() {
    return this.control.touched && this.control.invalid || this._hasError;
  }
  get translatedError() {
    return this._keyValuePipe.transform(this.control.errors)?.map(({
      key,
      value
    }) => translate(`${this._formErrorPrefix}.${key.toUpperCase()}`, {
      ...value
    })).join('<br>') || undefined;
  }
  get translatedHint() {
    return !!this.hint ? translate(this.hint) : undefined;
  }
  ngOnInit() {
    const ngControl = this._injector.get(NgControl, null, {
      self: true,
      optional: true
    });
    if (ngControl instanceof NgModel) {
      this.control = ngControl.control;
      // this._subscription = ngControl.control.valueChanges.subscribe((value) => {
      //   if (ngControl.model !== value || ngControl.viewModel !== value) {
      //     ngControl.viewToModelUpdate(value);
      //   }
      // });
    } else if (ngControl instanceof FormControlDirective) {
      this.control = ngControl.control;
    } else if (ngControl instanceof FormControlName) {
      const container = this._injector.get(ControlContainer).control;
      this.control = container.controls[ngControl.name];
    } else {
      this.control = new FormControl();
    }
    this.onInit();
  }
  ngOnDestroy() {
    this._subscription?.unsubscribe();
    this.onDestroy();
  }
  onValueChange(value) {
    this.controlValue = value;
    this.onChanged(value);
    this.onTouched();
    this.valueChange.emit(value);
  }
  onClear() {
    this.onValueChange(null);
    this.clear.emit();
  }
  static {
    this.ɵfac = function Form_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || Form)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: Form,
      inputs: {
        formControl: "formControl",
        formControlName: "formControlName",
        nsaId: "nsaId",
        nsaName: "nsaName",
        size: "size",
        className: "className",
        label: "label",
        icon: "icon",
        placeholder: "placeholder",
        hint: "hint",
        clearable: "clearable",
        clearTitle: "clearTitle",
        cardView: "cardView",
        translateParams: "translateParams",
        _hasError: [0, "hasError", "_hasError"],
        _errorMessage: [0, "errorMessage", "_errorMessage"]
      },
      outputs: {
        valueChange: "valueChange",
        clear: "clear"
      },
      standalone: false
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Form, [{
    type: Directive,
    args: [{
      standalone: false
    }]
  }], null, {
    formControl: [{
      type: Input
    }],
    formControlName: [{
      type: Input
    }],
    nsaId: [{
      type: Input,
      args: [{
        required: true
      }]
    }],
    nsaName: [{
      type: Input,
      args: [{
        required: true
      }]
    }],
    size: [{
      type: Input
    }],
    className: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    icon: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    hint: [{
      type: Input
    }],
    clearable: [{
      type: Input
    }],
    clearTitle: [{
      type: Input
    }],
    cardView: [{
      type: Input
    }],
    translateParams: [{
      type: Input
    }],
    _hasError: [{
      type: Input,
      args: ['hasError']
    }],
    _errorMessage: [{
      type: Input,
      args: ['errorMessage']
    }],
    valueChange: [{
      type: Output
    }],
    clear: [{
      type: Output
    }]
  });
})();
class InputGroupComponent {
  constructor() {
    this.className = '';
    this.hasValue = false;
    this.clearable = false;
    this.cardView = false;
    this.disabled = false;
    this.clear = new EventEmitter();
  }
  get subtext() {
    return this.invalid ? this.translatedError : this.translatedHint;
  }
  static {
    this.ɵfac = function InputGroupComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || InputGroupComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: InputGroupComponent,
      selectors: [["nsa-input-group"]],
      inputs: {
        nsaId: "nsaId",
        size: "size",
        className: "className",
        label: "label",
        icon: "icon",
        required: "required",
        invalid: "invalid",
        translatedError: "translatedError",
        translatedHint: "translatedHint",
        hasValue: "hasValue",
        clearable: "clearable",
        cardView: "cardView",
        clearTitle: "clearTitle",
        disabled: "disabled"
      },
      outputs: {
        clear: "clear"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      consts: [["labelRef", ""], [4, "transloco"], [3, "ngClass"], [1, "card-header"], [1, "input-group-text", 3, "id"], [1, "btn", "btn-clear", 3, "title"], [1, "form-text", 3, "ngClass", "innerHtml"], [4, "ngTemplateOutlet"], [1, "form-label", 3, "for"], ["class", "required", 3, "ngClass", 4, "ngIf"], [1, "required", 3, "ngClass"], [1, "btn", "btn-clear", 3, "click", "title"]],
      template: function InputGroupComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵtemplate(0, InputGroupComponent_ng_container_0_Template, 11, 16, "ng-container", 1);
        }
      },
      dependencies: [SharedModule, i1.NgClass, i1.NgIf, i1.NgTemplateOutlet, i2.TranslocoDirective],
      styles: ["[_nghost-%COMP%]{display:contents}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InputGroupComponent, [{
    type: Component,
    args: [{
      selector: 'nsa-input-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [SharedModule],
      template: "<ng-container *transloco=\"let transloco\">\r\n  <div class=\"{{className}}\" [ngClass]=\"{ 'card card-input': !!cardView }\">\r\n    @if(!!cardView) {\r\n      <div class=\"card-header\">\r\n        <ng-container *ngTemplateOutlet=\"labelRef\"></ng-container>\r\n      </div>\r\n    } @else if(!!label) {\r\n      <ng-container *ngTemplateOutlet=\"labelRef\"></ng-container>\r\n    }\r\n\r\n    <ng-template #labelRef>\r\n      <label\r\n        [for]=\"nsaId\"\r\n        class=\"form-label\">\r\n        {{ label }}\r\n        <span\r\n          *ngIf=\"required\"\r\n          class=\"required\"\r\n          [ngClass]=\"{ 'text-danger': invalid }\">\r\n          *\r\n        </span>\r\n      </label>\r\n    </ng-template>\r\n\r\n    <div class=\"input-group input-group-{{size}} has-validation\" [ngClass]=\"{ 'is-input-group-invalid': invalid }\">\r\n      @if(!!icon) {\r\n        <span\r\n          id=\"{{nsaId}}-icon\"\r\n          class=\"input-group-text\">\r\n          <i class=\"bi {{icon}}\"></i>\r\n        </span>\r\n      }\r\n\r\n      <ng-content></ng-content>\r\n\r\n      @if(!!clearable && !!hasValue && !disabled) {\r\n        <button\r\n          [title]=\"!!clearTitle ? transloco(clearTitle) : transloco('NSA.ACTION.CLEAR')\"\r\n          class=\"btn btn-clear\"\r\n          (click)=\"clear.emit()\">\r\n          &times;\r\n        </button>\r\n      }\r\n    </div>\r\n  </div>\r\n  @if(invalid || !!translatedHint) {\r\n    <div\r\n      class=\"form-text\"\r\n      [ngClass]=\"{ 'text-danger': invalid }\"\r\n      [innerHtml]=\"subtext\">\r\n    </div>\r\n  }\r\n</ng-container>\r\n",
      styles: [":host{display:contents}\n"]
    }]
  }], null, {
    nsaId: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    className: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    icon: [{
      type: Input
    }],
    required: [{
      type: Input
    }],
    invalid: [{
      type: Input
    }],
    translatedError: [{
      type: Input
    }],
    translatedHint: [{
      type: Input
    }],
    hasValue: [{
      type: Input
    }],
    clearable: [{
      type: Input
    }],
    cardView: [{
      type: Input
    }],
    clearTitle: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    clear: [{
      type: Output
    }]
  });
})();
class FormSharedModule {
  static {
    this.ɵfac = function FormSharedModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FormSharedModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: FormSharedModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [InputGroupComponent, SharedModule, SharedModule, FormsModule, ReactiveFormsModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormSharedModule, [{
    type: NgModule,
    args: [{
      imports: [InputGroupComponent, SharedModule],
      exports: [SharedModule, FormsModule, ReactiveFormsModule, InputGroupComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Form, FormSharedModule, InputGroupComponent };
